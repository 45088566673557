import React from 'react';
import Button from "../../../../Components/Button/Button";
import Clouds from "../../../../Components/Clouds/Clouds";
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import classes from "./GrabDescription.module.scss";


const GrabDescription = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10">
                        <Banner color={'blue'}>Fogd és vidd</Banner>
                        <div className="my-auto mt-10 font-bold max-w-[600px] text-left text-[30px]">

                            <p className="mb-6 text-3xl">
                                Fogd és vidd játék.
                            </p>
                            <p className="mb-6 text-3xl">
                                Ebben a játékban a kivágott elemeket kell behúzni a üres helyekre.
                            </p>
                            <p className="mb-12 text-3xl">
                                A feladat teljesítésére 40 mp áll rendelkezésre.
                            </p>
                            <p className="mb-32 text-3xl">
                                Készen állsz a kihívásra? Akkor rajta!
                            </p>
                            <div className="text-center">
                                <Button text={'Start'} click={() => props.setActiveScreen('game')}/>
                            </div>

                        </div>
                    </div>
                </Card>
                <div className={'z-0 ' + classes['wrapper']}>
                    <img src="assets/images/games/truth/description.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-10 mt-24 ' + classes['animations']}
                         alt=""/>
                </div>
            </div>
        </div>
    );
};

export default GrabDescription;