export const questions = [
    {
        id: 1,
        question: 'Kreatív tervezés és DTP, Szoftverfejlesztés, Online marketing',
        answerIds: [1, 2, 3, 4],  //lehet kevesebb is
        correctAnswers: 1
    },
    {
        id: 2,
        question: 'Roxfort',
        answerIds: [5, 6, 7],
        correctAnswers: 6
    },
    {
        id: 3,
        question: 'Pikachu, Jolteon, Pichu, Shinx',
        answerIds: [8, 9, 10, 11],
        correctAnswers: 10
    },
    {
        id: 4,
        question: 'Red Eye',
        answerIds: [12, 13, 14, 15],
        correctAnswers: 12
    },
    {
        id: 5,
        question: 'Demogorgon',
        answerIds: [16, 17, 18, 19],
        correctAnswers: 19
    },
]

export const answers = [

    {
        id: 1,
        answer: 'Mikkel foglalkozik a Jacsomedia?',
    },
    {
        id: 2,
        answer: 'Mikkel foglalkozik egy átlagos cég?',
    },
    {
        id: 3,
        answer: 'Mikhez nem értek én?',
    },
    {
        id: 4,
        answer: 'Mikhez kell felsőfokú végzettség és covid oltás?',
    },

    {
        id: 5,
        answer: 'Mi egy büdös sajt neve?',
    },
    {
        id: 6,
        answer: 'Hogy hívják a Harry Potterben a varázslóiskolát?',
    },
    {
        id: 7,
        answer: 'Mi egy híres zenekar neve?',
    },
    {
        id: 8,
        answer: 'Mely pokemonokkal lehet kezdeni az egyes játékokban?',
    },
    {
        id: 9,
        answer: 'Mely pokemonok hasonlítanak egérre?',
    },
    {
        id: 10,
        answer: 'Mely pokemonok electro típusúak?',
    },
    {
        id: 11,
        answer: 'Mely pokemonok a legerősebbek?',
    },
    {
        id: 12,
        answer: 'Melyik egy kávétípus?',
    },
    {
        id: 13,
        answer: 'Melyik egy betegség?',
    },
    {
        id: 14,
        answer: 'Melyik egy szlenges kifejezés?',
    },
    {
        id: 15,
        answer: 'Melyik egy különleges étel?',
    },
    {
        id: 16,
        answer: 'Melyik egy lemezkiadó cég neve? ',
    },
    {
        id: 17,
        answer: 'Melyik egy márka neve?',
    },
    {
        id: 18,
        answer: 'Melyik egy betegség neve?',
    },
    {
        id: 19,
        answer: 'Melyik egy feltehetően nem létező lény?',
    },


]
