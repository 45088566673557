import React, {useState} from 'react';
import Clouds from "../../../Components/Clouds/Clouds";
import Button from "../../../Components/Button/Button";
import BonusDescription from "./Screens/BonusDescription";
import BonusGame from "./Screens/BonusGame";
import Exit from "../../Exit/Exit";
import Retry from "../../Retry/Retry";

const Falling = (props) => {
    const [activeScreen, setActiveScreen] = useState('description');
    const [isGameRunning, setIsGameRunning] = useState(false);
    const [points, setPoints] = useState(0)

    const screens = {
        'description': <BonusDescription setActiveScreen={setActiveScreen}/>,
        'game': <BonusGame setActiveScreen={setActiveScreen}/>,
        'exit': <Exit setActiveView={props.setActiveView}/>,
        'retry': <Retry setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>,
    }
    return (
        <div>
            <Clouds>
                <div className="container mx-auto ">
                    <div className="absolute left-10 top-10">
                        <Button class={"back"} click={() => {
                            props.setActiveView('games')
                        }}/>
                    </div>
                    {screens[activeScreen]}
                </div>
            </Clouds>
        </div>
    );
};

export default Falling;