import React, {useEffect, useState} from "react";
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import Timer from "../../../Timer/Timer";
import {cards, gameTimeSeconds} from "../Config/scratchConfig";
import ScratchCard from "../Components/ScratchCard";

const ScratchGame = (props) => {
    const [gameCards] = useState([...cards].sort(() => Math.random() - .5));
    const maxScratch = 5;
    const maxWinnerCard = 3;
    const [cardCount, setCardCount] = useState(0);
    const [winnerCardCount, setWinnerCardCount] = useState(0);

    useEffect(() => {
        if (cardCount === maxScratch && winnerCardCount < maxWinnerCard)
            props.setActiveScreen('retry');
    }, [cardCount]);

    useEffect(() => {
        if (winnerCardCount === maxWinnerCard)
            props.setActiveScreen('exit');
    }, [winnerCardCount]);

    const getCards = () => {
        return gameCards.map(card => <ScratchCard card={card} setCardCount={setCardCount} key={card.id}
                                                  cardCount={cardCount} winnerCardCount={winnerCardCount}
                                                  setWinnerCardCount={setWinnerCardCount}/>)
    }

    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 mb-24">
                <Card>
                    <div className="flex flex-col mx-20 mt-5 mb-0">
                        <Banner color={'blue'}>Kaparós sorsjegy</Banner>
                        <div className="mb-0 mt-5">
                            <div className="grid grid-cols-4 gap-10">
                                {getCards()}
                            </div>
                        </div>
                        <div className="flex my-16">
                            <Timer time={gameTimeSeconds} onEnd={() => {
                                props.setActiveScreen('timeout');
                            }}/>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default ScratchGame;