import React from 'react';
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import Button from "../../../../Components/Button/Button";
import classes from "./ReverseDescription.module.scss";

const ReverseDescription = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 gap-x-6 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10 max-w-[600px]">
                        <Banner color={'blue'}>kvízjáték</Banner>
                        <div className="mx-auto mt-8 text-left">
                            <p className={'text-3xl font-bold mb-4'}>Fordított kvízjáték.</p>
                            <p className={'text-3xl font-bold mb-4'}>Ebben a játékban ki kell találnod, hogy a megadott
                                válaszhoz melyik kérdés tartozhatott. Minden válaszhoz pontosan csak egy jó kérdés
                                tartozik.</p>
                            <p className={'text-3xl font-bold mb-4'}>A játékra összesen 60 másodperc fog a
                                rendelkezésedre állni.</p>
                            <p className={'text-3xl font-bold mb-12'}>Siess, mielőtt lejár az idő és vigyázz a trükkös
                                kérdésekkel!</p>

                            <p className={'text-3xl font-bold'}>Készen állsz a kihívásra? Akkor rajta!</p>
                        </div>
                        <div className="mx-auto mt-10">
                            <Button text={'Start'} click={() => props.setActiveScreen('game')}/>
                        </div>

                    </div>

                </Card>
                <div className={'z-0 ' + classes['wrapper']}>
                    <img src="assets/images/games/reverse/figura_mosolyog.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-14 mt-24 ' + classes['animations']}
                         alt=""/>
                </div>
            </div>


        </div>
    );
};

export default ReverseDescription;