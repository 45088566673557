import Counter from "../../../../Components/Counter/Counter";
import {maxQuizNumber} from "../../../../Config/appConfig";
import Card from "../../../../Components/Card/Card";
import {answers} from "../../../../Config/quizConfig";
import {useEffect, useState} from "react";
import AnswerButton from "../../../../Components/AnswerButton/AnswerButton";
import Banner from "../../../../Components/Banner/Banner";
import Timer from "../../../Timer/Timer";
import Button from "../../../../Components/Button/Button";

const QuizGame = (props) => {
        const [quizQuestions] = useState(props.quizQuestions);
        let [quizNumber, setQuizNumber] = useState(1);
        const [activeQuizAnswers, setActiveQuizAnswers] = useState([]);
        const [activeQuiz, setActiveQuiz] = useState({});
        const [selectedAnswers, setSelectedAnswers] = useState([]);
        const [isCounting, setIsCounting] = useState(true);
        let [countedGoodAnswers, setCountedGoodAnswers] = useState(0);
        let [isShowButton, setIsShowButton] = useState(false)

        useEffect(() => {
                if (quizNumber - 1 === quizQuestions.length) {
                    props.setActiveScreen('retry');
                } else {
                    const array = [...answers].filter((a) => quizQuestions[quizNumber - 1].answerIds.includes(a.id)).sort(() => Math.random() - .5);
                    setActiveQuiz(quizQuestions[quizNumber - 1]);
                    setActiveQuizAnswers(array);
                    setSelectedAnswers([]);
                    setIsCounting(true);
                }
                setIsShowButton(false)
                props.setIsWrongModal(false);
                props.setIsCorrectModal(false);
            }, [quizNumber]
        )


        useEffect(() => {
            if (quizNumber === quizQuestions.length) {
                if (countedGoodAnswers === maxQuizNumber) {
                    props.setActiveScreen('exit');

                } else {
                    props.setActiveScreen('retry');
                }
                props.setIsWrongModal(false);
                props.setIsCorrectModal(false);
            }
        }, [countedGoodAnswers])

        const getQuizQuestion = () => {
            return <Card>
                <div className={'w-[80vw] flex flex-col p-4'}>
                    <Banner color={'blue'}>kvízjáték</Banner>
                    <div
                        className={'w-3/4 mx-auto text-3xl font-bold mt-5'}>  {activeQuiz.question}</div>

                </div>
            </Card>
        }
        const getAnswers = () => {
            return activeQuizAnswers.map(a => <AnswerButton key={a.id} text={a.answer}
                                                            class={!isCounting && (activeQuiz.correctAnswers.includes(a.id) ? 'correct' : 'wrong')}
                                                            click={() => {
                                                                let array = selectedAnswers;
                                                                const index = array.indexOf(a.id);
                                                                if (index > -1) {
                                                                    array.splice(index, 1);
                                                                } else {
                                                                    array.push(a.id);
                                                                }
                                                                setSelectedAnswers(array);
                                                                array.length > 0 ? setIsShowButton(true) : setIsShowButton(false)
                                                            }}/>
            )
        }

        const gameEnd = () => {
            setIsCounting(false);
            if (arrayCompare(selectedAnswers, activeQuiz.correctAnswers)) {
                setTimeout(() => {
                    props.setIsCorrectModal(true);
                }, 800);
                countedGoodAnswers++;
            } else {
                setTimeout(() => {
                    props.setIsWrongModal(true);
                }, 800);
            }
            setTimeout(() => {
                setCountedGoodAnswers(countedGoodAnswers);
                setTimeout(() => {
                    setQuizNumber(quizNumber + 1)
                }, 10);
            }, 4000)
        }

        function arrayCompare(_arr1, _arr2) {
            if (
                !Array.isArray(_arr1)
                || !Array.isArray(_arr2)
                || _arr1.length !== _arr2.length
            ) {
                return false;
            }

            const arr1 = _arr1.concat().sort();
            const arr2 = _arr2.concat().sort();

            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) {
                    return false;
                }
            }
            return true;
        }

        return (
            <div>
                <Counter>
                    {quizNumber + '/' + maxQuizNumber}
                </Counter>
                <div className="flex flex-col mt-10">
                    {getQuizQuestion()}
                </div>
                <div className={'grid grid-cols-2 gap-x-32 gap-y-12 my-12 mx-20'}>
                    {getAnswers()}
                </div>
                <div>
                    {isShowButton && <Button text={'Tovább'} click={() => gameEnd()}/>}
                </div>
                <div className={'fixed w-[100%] bottom-[50px] left-0'}>
                    {isCounting && <Timer time={30} onEnd={gameEnd}/>}
                </div>
            </div>
        );
    }
;

export default QuizGame;