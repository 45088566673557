import React from 'react';
import Button from "../../Components/Button/Button";
import Card from "../../Components/Card/Card";
import classes from "./Start.module.scss"
import Clouds from "../../Components/Clouds/Clouds";
import Timer from "../Timer/Timer";
import Cloud2 from "../../Components/CloudDesign/Cloud2";
import Banner from "../../Components/Banner/Banner";

const Start = (props) => {
    return (
        <>
            <Clouds>
                <div className="grid grid-cols-5 container justify-around my-20 mx-auto">
                    <Button text={'start'}/>
                    <Button text={'disabled'} disabled/>
                    <Button text={'wrong'} class={'wrong'}/>
                    <Button text={'correct'} class={'correct'}/>
                    <Button text={'back'} class={'back'}/>
                </div>
                <div className="flex my-20">
                    <Timer time={30} onEnd={() => {
                    }}/>
                </div>
                <Button text={'kezdjük el!'} click={() => {
                    props.setActiveView('home')
                }}/>
                <div className="flex flex-col flex-wrap justify-center py-20">
                    <Banner color={'blue'}>Start</Banner>
                    <Banner color={'red'}>Start</Banner>
                    <Banner color={'green'}>Start</Banner>
                </div>
            </Clouds>
            <Cloud2>
                <div className="flex gap-5 justify-around">
                    <Card>
                        <div className="relative px-5 flex flex-col w-fit">
                            <div className="pt-20">
                                <img src="assets/images/games/hands.png" className={'max-w-[300px]'} alt=""/>
                            </div>
                            <div className="pb-16 pt-5 uppercase font-bold">Igaz-Hamis</div>
                            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                                <Button text={'start'}/>
                            </div>
                        </div>
                    </Card>
                    <Card class={'secondary'}>
                        <div className="relative px-5 flex flex-col w-fit">
                            <div className="py-10">
                                <img src="assets/images/games/locket.png" alt="" className={'max-h-[150px] mx-auto'}/>
                            </div>

                            <div
                                className={"text-3xl uppercase font-bold text-brand_light px-3 rounded-3xl bg-brand " + classes['closed']}>
                                <div>lezárva
                                </div>
                            </div>

                            <div
                                className={"text-3xl uppercase font-bold text-white " + classes['bonus']}>
                                <div>bónusz
                                </div>
                            </div>

                        </div>
                    </Card>

                </div>


            </Cloud2>
            <div className={classes['marks'] + ' min-h-screen'}>
            </div>

        </>
    );
};

export default Start;