import React, {useState} from 'react';
import GrabDescription from "./Screens/GrabDescription";
import GrabGame from "./Screens/GrabGame";
import Button from "../../../Components/Button/Button";
import Clouds from "../../../Components/Clouds/Clouds";
import Exit from "../../Exit/Exit";
import Retry from "../../Retry/Retry";

const Grab = (props) => {
    const [activeScreen, setActiveScreen] = useState('description');

    const dragOver = event => {
        event.preventDefault();
    }

    const screens = {
        'description': <GrabDescription setActiveScreen={setActiveScreen}/>,
        'game': <GrabGame setActiveScreen={setActiveScreen} drop={props.drop} dragOver={dragOver}/>,
        'exit': <Exit setActiveView={props.setActiveView}/>,
        'retry': <Retry setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>,
    }

    return (
        <div onDrop={props.drop} onDragOver={dragOver}>
            <Clouds>
                <div className="mx-auto">
                    <div className="absolute left-10 top-10">
                        <Button class={"back"} click={() => {
                            props.setActiveView('games')
                        }}/>
                    </div>
                    <div className="z-10 flex justify-center align-center">
                        {screens[activeScreen]}
                    </div>
                </div>
            </Clouds>
        </div>
    );
};

export default Grab;