import React, {useState} from 'react';
import ScratchDescription from "./Screens/ScratchDescription";
import Button from "../../../Components/Button/Button";
import Clouds from "../../../Components/Clouds/Clouds";
import ScratchGame from "./Screens/ScratchGame";
import Exit from "../../Exit/Exit";
import Retry from "../../Retry/Retry";
import ScratchTimeOut from "./Screens/ScratchTimeOut";

const Scratch = (props) => {
    const [activeScreen, setActiveScreen] = useState('description');

    const screens = {
        'description': <ScratchDescription setActiveScreen={setActiveScreen}/>,
        'game': <ScratchGame setActiveScreen={setActiveScreen}/>,
        'exit': <Exit setActiveView={props.setActiveView}/>,
        'retry': <Retry setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>,
        'timeout': <ScratchTimeOut setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>
    }

    return (
        <div>
            <Clouds>
                <div className="container mx-auto ">
                    <div className="absolute left-10 top-10">
                        <Button class={"back"} click={() => {
                            props.setActiveView('games')
                        }}/>
                    </div>
                    <div className="z-10">
                        {screens[activeScreen]}
                    </div>
                </div>
            </Clouds>
        </div>
    );
};

export default Scratch;