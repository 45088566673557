import React from 'react';
import classes from './Coins.module.scss'

const Coins = (props) => {

    const handleDoubleClick = event => {
        if (event.detail === 2) {
            window.location.reload()
        }
    }

    return (
        <div className={classes['coin-holder']} onClick={handleDoubleClick}>
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="240" height="207"
                 viewBox="0 0 481 207">
                <defs>
                    <filter id="Rectangle_38" x="0" y="0" width="481" height="207" filterUnits="userSpaceOnUse">
                        <feOffset dy="6" input="SourceAlpha"/>
                        <feGaussianBlur result="blur"/>
                        <feFlood floodOpacity="0.161"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Group_452" data-name="Group 452" transform="translate(-3359 -1885)">
                    <g transform="matrix(1, 0, 0, 1, 3359, 1885)" filter="url(#Rectangle_38)">
                        <path id="Rectangle_38-2" data-name="Rectangle 38"
                              d="M100,0H481a0,0,0,0,1,0,0V201a0,0,0,0,1,0,0H100A100,100,0,0,1,0,101v-1A100,100,0,0,1,100,0Z"
                              fill="#fff"/>
                    </g>
                    <path id="Path_6" data-name="Path 6"
                          d="M65.5,0A65.5,65.5,0,1,0,131,65.5,65.5,65.5,0,0,0,65.5,0M48.189,108.915H35.9V37.8H48.189ZM63.823,85.209H51.529V37.8H63.823Zm15.675,0H67.2V37.8H79.5Zm15.606,0H82.81V37.8H95.1Z"
                          transform="translate(3397.137 1919)" fill="#fecd00"/>
                    <path id="Path_7" data-name="Path 7"
                          d="M139.727,82.737V45.986h8.142q5.762,0,8.131,2.359t2.369,8.142V70.924q0,6.049-2.41,8.931t-8.131,2.882Zm5.25-3.938h2.892a9.051,9.051,0,0,0,2.214-.236,2.975,2.975,0,0,0,1.5-.861,7.058,7.058,0,0,0,.9-1.19,4.66,4.66,0,0,0,.461-1.753q.134-1.189.154-1.959t.021-2.389V57.8q0-1-.011-1.6t-.061-1.4a9.167,9.167,0,0,0-.154-1.282q-.1-.472-.287-1.056a2.654,2.654,0,0,0-.461-.913,7,7,0,0,0-.678-.687,2.582,2.582,0,0,0-.922-.533,7.886,7.886,0,0,0-1.22-.287,9.841,9.841,0,0,0-1.559-.113h-2.789Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <rect id="Rectangle_39" data-name="Rectangle 39" width="5.25" height="36.75"
                          transform="translate(3560.346 1964.986)" fill="#282828"/>
                    <path id="Path_8" data-name="Path 8"
                          d="M183.922,83.106a12.108,12.108,0,0,1-4.379-.718,8.141,8.141,0,0,1-3.024-1.948,8.932,8.932,0,0,1-1.867-3.138,18.361,18.361,0,0,1-.963-4.019,39.187,39.187,0,0,1-.267-4.861v-9.31q0-13.494,10.705-13.494a11.743,11.743,0,0,1,4.45.769,7.34,7.34,0,0,1,3.056,2.245,10.285,10.285,0,0,1,1.764,3.456,18.52,18.52,0,0,1,.7,4.583c.014.247.02.623.02,1.128h-5.25V56.692a21.628,21.628,0,0,0-.133-2.225,9.509,9.509,0,0,0-.411-1.836,4.012,4.012,0,0,0-.809-1.466,3.6,3.6,0,0,0-1.364-.9,5.452,5.452,0,0,0-2.02-.339,5.675,5.675,0,0,0-2.7.585,3.966,3.966,0,0,0-1.682,1.7,8.676,8.676,0,0,0-.83,2.461,17.536,17.536,0,0,0-.246,3.127V69.612q0,1.313.082,2.369t.277,2.153a7.406,7.406,0,0,0,.575,1.9,6.222,6.222,0,0,0,.943,1.446,3.621,3.621,0,0,0,1.414.984,5.267,5.267,0,0,0,1.959.338,4.787,4.787,0,0,0,2.276-.512,4.267,4.267,0,0,0,1.529-1.323,6.463,6.463,0,0,0,.891-2.061,15.209,15.209,0,0,0,.441-2.482q.112-1.23.113-2.809V68.3h-5.25V64.4h10.5V82.737h-3.938L189.172,78.8q-1.537,4.306-5.25,4.307"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <rect id="Rectangle_40" data-name="Rectangle 40" width="5.25" height="36.75"
                          transform="translate(3596.05 1964.986)" fill="#282828"/>
                    <path id="Path_9" data-name="Path 9"
                          d="M213.7,82.736V50.662h-6.562V45.986h18.375v4.676h-6.563V82.736Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_10" data-name="Path 10"
                          d="M225.286,82.736l7.875-36.75h5.25l7.875,36.75h-5.25l-1.969-9.187h-6.562l-1.969,9.187Zm7.875-13.125h5.25l-2.625-15.75Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_11" data-name="Path 11" d="M249.239,82.736V45.986h5.25V78.8h9.188v3.938Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_12" data-name="Path 12"
                          d="M272.352,82.736l7.875-36.75h5.25l7.875,36.75H288.1l-1.97-9.187H279.57L277.6,82.736Zm7.875-13.125h5.25l-2.625-15.75Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_13" data-name="Path 13"
                          d="M305.82,83.106a12.108,12.108,0,0,1-4.379-.718,8.141,8.141,0,0,1-3.024-1.948A8.932,8.932,0,0,1,296.55,77.3a18.361,18.361,0,0,1-.963-4.019,39.184,39.184,0,0,1-.267-4.861v-9.31q0-13.494,10.7-13.494a11.743,11.743,0,0,1,4.45.769,7.34,7.34,0,0,1,3.056,2.245,10.285,10.285,0,0,1,1.764,3.456,18.52,18.52,0,0,1,.7,4.583c.014.247.02.623.02,1.128h-5.25V56.692a21.623,21.623,0,0,0-.133-2.225,9.507,9.507,0,0,0-.411-1.836,4.012,4.012,0,0,0-.809-1.466,3.6,3.6,0,0,0-1.364-.9,5.452,5.452,0,0,0-2.02-.339,5.675,5.675,0,0,0-2.7.585,3.966,3.966,0,0,0-1.682,1.7,8.676,8.676,0,0,0-.83,2.461,17.536,17.536,0,0,0-.246,3.127V69.612q0,1.313.082,2.369t.277,2.153a7.407,7.407,0,0,0,.575,1.9,6.223,6.223,0,0,0,.943,1.446,3.621,3.621,0,0,0,1.414.984,5.267,5.267,0,0,0,1.959.338,4.787,4.787,0,0,0,2.276-.512,4.267,4.267,0,0,0,1.529-1.323,6.462,6.462,0,0,0,.891-2.061,15.21,15.21,0,0,0,.441-2.482q.113-1.23.113-2.809V68.3h-5.25V64.4h10.5V82.737h-3.938L311.07,78.8q-1.537,4.306-5.25,4.307"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_14" data-name="Path 14"
                          d="M320.565,82.736V45.986H335v3.937h-9.188V61.736h6.563v3.938h-6.563V78.8H335v3.938Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_15" data-name="Path 15"
                          d="M338.223,82.736V45.986h3.938l9.187,22.723V45.986h5.25v36.75h-3.732l-9.393-24.445V82.736Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_16" data-name="Path 16"
                          d="M371.281,83.106a11.681,11.681,0,0,1-4.081-.656,7.728,7.728,0,0,1-2.892-1.815,8.954,8.954,0,0,1-1.836-2.892,15.888,15.888,0,0,1-.994-3.7,31.133,31.133,0,0,1-.287-4.451V59.112a31.118,31.118,0,0,1,.287-4.45,15.883,15.883,0,0,1,.994-3.7,8.815,8.815,0,0,1,1.836-2.881,7.774,7.774,0,0,1,2.892-1.805,13.169,13.169,0,0,1,8.511.1,6.911,6.911,0,0,1,2.984,2.235,10.447,10.447,0,0,1,1.682,3.425,19.257,19.257,0,0,1,.666,4.552q.02.39.02,1.21h-5.25V56.63q-.02-1.086-.082-1.856a13.761,13.761,0,0,0-.225-1.589,4.437,4.437,0,0,0-.483-1.364,4.641,4.641,0,0,0-.8-1A2.91,2.91,0,0,0,373,50.14a6.167,6.167,0,0,0-1.723-.216,5.287,5.287,0,0,0-2.184.411,3.7,3.7,0,0,0-1.425,1.056,4.549,4.549,0,0,0-.789,1.733,12.524,12.524,0,0,0-.36,2.122q-.081,1.037-.082,2.553V70.924a22.553,22.553,0,0,0,.113,2.379,11.032,11.032,0,0,0,.441,2.123,6.086,6.086,0,0,0,.85,1.774,3.842,3.842,0,0,0,1.395,1.158,4.467,4.467,0,0,0,2.041.441,4.2,4.2,0,0,0,2.01-.451,3.692,3.692,0,0,0,1.322-1.148,5.567,5.567,0,0,0,.76-1.784,12.736,12.736,0,0,0,.358-2.113q.083-1.024.082-2.379V69.612h5.25v1.312a22.172,22.172,0,0,1-.5,5,11.3,11.3,0,0,1-1.619,3.8,7.1,7.1,0,0,1-3.035,2.513,11.245,11.245,0,0,1-4.625.861"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_17" data-name="Path 17"
                          d="M389.738,82.736V70.923l-7.875-24.937h5.25l5.25,18.375,5.25-18.375h5.25l-7.875,24.937V82.736Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_18" data-name="Path 18"
                          d="M139.792,36.794a3.279,3.279,0,0,0,1.154-.147.778.778,0,0,0,.469-.54,5.169,5.169,0,0,0,.113-1.288V25.762h1.064v9.114a5.674,5.674,0,0,1-.175,1.582,1.42,1.42,0,0,1-.666.854,2.968,2.968,0,0,1-1.441.28h-.518Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_19" data-name="Path 19"
                          d="M147.407,25.762h1.121l2.408,11.34h-1.051l-.559-3.038h-2.7l-.574,3.038h-1.036Zm1.779,7.532-1.205-6.118-1.2,6.118Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_20" data-name="Path 20"
                          d="M153.729,36.3a4.578,4.578,0,0,1-.639-2.6V29.191a4.56,4.56,0,0,1,.639-2.625,2.526,2.526,0,0,1,2.232-.917,2.424,2.424,0,0,1,2.074.826,3.972,3.972,0,0,1,.614,2.394v.686h-1.008V28.9a5.692,5.692,0,0,0-.126-1.323,1.376,1.376,0,0,0-.49-.791,1.7,1.7,0,0,0-1.05-.28,1.809,1.809,0,0,0-1.135.308,1.541,1.541,0,0,0-.531.847,5.588,5.588,0,0,0-.141,1.379v4.83a3.551,3.551,0,0,0,.4,1.918,1.56,1.56,0,0,0,1.407.6,1.382,1.382,0,0,0,1.33-.609,3.967,3.967,0,0,0,.336-1.855v-.742h1.008v.672a4.421,4.421,0,0,1-.6,2.506,2.341,2.341,0,0,1-2.086.882,2.5,2.5,0,0,1-2.232-.938"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_21" data-name="Path 21"
                          d="M161.743,36.422a3.779,3.779,0,0,1-.8-2.261l.938-.279a4.268,4.268,0,0,0,.539,1.847,1.5,1.5,0,0,0,1.365.644,1.43,1.43,0,0,0,1.6-1.638,2.3,2.3,0,0,0-.329-1.232A6.477,6.477,0,0,0,164,32.285l-2-1.917a3.283,3.283,0,0,1-.791-1.058,2.994,2.994,0,0,1-.244-1.225,2.322,2.322,0,0,1,.692-1.8,2.653,2.653,0,0,1,1.869-.637,2.617,2.617,0,0,1,1.933.672,3.393,3.393,0,0,1,.783,2.2l-.91.238a3.407,3.407,0,0,0-.482-1.708,1.513,1.513,0,0,0-1.324-.56,1.709,1.709,0,0,0-1.162.378,1.406,1.406,0,0,0-.433,1.12,1.946,1.946,0,0,0,.181.854,2.941,2.941,0,0,0,.616.8l2,1.89a6.663,6.663,0,0,1,1.211,1.492,3.225,3.225,0,0,1,.442,1.644,2.485,2.485,0,0,1-.715,1.9,2.641,2.641,0,0,1-1.89.673,2.524,2.524,0,0,1-2.03-.819"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_22" data-name="Path 22"
                          d="M169.283,36.332a4.3,4.3,0,0,1-.665-2.576v-4.7a4.081,4.081,0,0,1,.672-2.527,2.669,2.669,0,0,1,2.254-.875,2.622,2.622,0,0,1,2.226.883,4.09,4.09,0,0,1,.672,2.519v4.718a4.316,4.316,0,0,1-.658,2.57,2.591,2.591,0,0,1-2.24.9,2.617,2.617,0,0,1-2.261-.91m3.689-.554a3.32,3.32,0,0,0,.392-1.784V28.855a3.254,3.254,0,0,0-.385-1.764,1.592,1.592,0,0,0-1.435-.587,1.634,1.634,0,0,0-1.457.581,3.2,3.2,0,0,0-.391,1.77v5.139a3.321,3.321,0,0,0,.391,1.792,1.61,1.61,0,0,0,1.457.6,1.569,1.569,0,0,0,1.428-.609"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_23" data-name="Path 23"
                          d="M177.436,25.761H178.5l2.241,9.842,2.282-9.842h1.063l.281,11.34h-.882l-.225-9.029L181.09,37.1h-.685l-2.143-9.016-.223,9.016h-.882Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_24" data-name="Path 24"
                          d="M187.293,25.761h4.1v.826h-3.025v4.256h2.464v.784h-2.464V36.3h3.052v.8h-4.13Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_25" data-name="Path 25"
                          d="M193.929,25.761h2.465a2.906,2.906,0,0,1,2.379.861,3.836,3.836,0,0,1,.7,2.471V33.6a4.264,4.264,0,0,1-.68,2.6,2.7,2.7,0,0,1-2.3.9h-2.562ZM196.421,36.3a1.967,1.967,0,0,0,1.281-.356,1.645,1.645,0,0,0,.553-.953,7.8,7.8,0,0,0,.126-1.561V29.2a5.779,5.779,0,0,0-.153-1.477,1.463,1.463,0,0,0-.588-.847,2.249,2.249,0,0,0-1.261-.294h-1.386V36.3Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <rect id="Rectangle_41" data-name="Rectangle 41" width="1.063" height="11.34"
                          transform="translate(3599.438 1944.761)" fill="#282828"/>
                    <path id="Path_26" data-name="Path 26"
                          d="M208.208,25.762h1.121l2.407,11.34h-1.05l-.56-3.038h-2.7L206.85,37.1h-1.036Zm1.778,7.532-1.2-6.118-1.2,6.118Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_27" data-name="Path 27"
                          d="M140.3,91.615h2.464a2.906,2.906,0,0,1,2.38.861,3.834,3.834,0,0,1,.7,2.471v4.508a4.267,4.267,0,0,1-.679,2.6,2.7,2.7,0,0,1-2.3.9H140.3Zm2.492,10.542a1.957,1.957,0,0,0,1.28-.357,1.641,1.641,0,0,0,.554-.952,7.808,7.808,0,0,0,.126-1.561V95.059a5.723,5.723,0,0,0-.154-1.477,1.456,1.456,0,0,0-.588-.847,2.246,2.246,0,0,0-1.26-.294h-1.387v9.716Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_28" data-name="Path 28"
                          d="M148.646,91.615h4.1v.826h-3.025V96.7h2.465v.784h-2.465v4.676h3.053v.8h-4.13Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_29" data-name="Path 29"
                          d="M155.674,102.276a3.783,3.783,0,0,1-.8-2.261l.938-.28a4.269,4.269,0,0,0,.539,1.848,1.5,1.5,0,0,0,1.365.644,1.43,1.43,0,0,0,1.6-1.638,2.3,2.3,0,0,0-.329-1.232,6.466,6.466,0,0,0-1.057-1.218l-2-1.918a3.24,3.24,0,0,1-.791-1.057,3,3,0,0,1-.245-1.225,2.323,2.323,0,0,1,.693-1.8,2.653,2.653,0,0,1,1.869-.637,2.615,2.615,0,0,1,1.932.672,3.393,3.393,0,0,1,.784,2.2l-.91.238a3.4,3.4,0,0,0-.483-1.708,1.513,1.513,0,0,0-1.323-.56,1.707,1.707,0,0,0-1.162.378,1.4,1.4,0,0,0-.434,1.12,1.933,1.933,0,0,0,.182.854,2.914,2.914,0,0,0,.615.8l2,1.89a6.687,6.687,0,0,1,1.212,1.491,3.233,3.233,0,0,1,.441,1.645,2.488,2.488,0,0,1-.714,1.9,2.647,2.647,0,0,1-1.89.672,2.524,2.524,0,0,1-2.03-.819"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <rect id="Rectangle_42" data-name="Rectangle 42" width="1.063" height="11.34"
                          transform="translate(3559.965 2010.615)" fill="#282828"/>
                    <path id="Path_30" data-name="Path 30"
                          d="M167.37,102.178a4.729,4.729,0,0,1-.637-2.681V95.073a4.66,4.66,0,0,1,.63-2.667,2.544,2.544,0,0,1,2.24-.9,2.509,2.509,0,0,1,2.108.819,3.94,3.94,0,0,1,.637,2.443v.392H171.34v-.392a3.526,3.526,0,0,0-.365-1.841,1.491,1.491,0,0,0-1.358-.567,1.776,1.776,0,0,0-1.141.322,1.559,1.559,0,0,0-.532.875,6.154,6.154,0,0,0-.133,1.407v4.662a3.945,3.945,0,0,0,.385,1.974,1.534,1.534,0,0,0,1.436.644,1.482,1.482,0,0,0,1.385-.651,3.971,3.971,0,0,0,.392-1.995v-1.33h-1.68v-.8h2.661v5.488H171.7l-.126-1.288a2.215,2.215,0,0,1-.728,1.064,2.1,2.1,0,0,1-1.316.378,2.43,2.43,0,0,1-2.163-.931"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_31" data-name="Path 31"
                          d="M175.3,91.615h.77l3.612,8.806V91.615h.924v11.34h-.756l-3.626-8.9v8.9H175.3Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_32" data-name="Path 32"
                          d="M195.4,91.615h2.464a2.906,2.906,0,0,1,2.38.861,3.834,3.834,0,0,1,.7,2.471v4.508a4.267,4.267,0,0,1-.679,2.6,2.7,2.7,0,0,1-2.3.9H195.4Zm2.492,10.542a1.956,1.956,0,0,0,1.28-.357,1.641,1.641,0,0,0,.554-.952,7.808,7.808,0,0,0,.126-1.561V95.059a5.723,5.723,0,0,0-.154-1.477,1.456,1.456,0,0,0-.588-.847,2.246,2.246,0,0,0-1.26-.294h-1.387v9.716Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_33" data-name="Path 33"
                          d="M203.749,91.615h4.1v.826h-3.025V96.7h2.465v.784h-2.465v4.676h3.053v.8h-4.13Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_34" data-name="Path 34"
                          d="M209.74,91.615h1.022l2.142,9.394,2.016-9.394h1.008l-2.562,11.34H212.4Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_35" data-name="Path 35"
                          d="M218.295,91.615h4.1v.826h-3.025V96.7h2.465v.784h-2.465v4.676h3.053v.8h-4.13Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_36" data-name="Path 36" d="M224.944,91.615h1.063v10.542h3.221v.8h-4.284Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_37" data-name="Path 37"
                          d="M231.728,102.185a4.3,4.3,0,0,1-.665-2.576V94.9a4.094,4.094,0,0,1,.671-2.527,2.672,2.672,0,0,1,2.254-.875,2.625,2.625,0,0,1,2.227.882,4.091,4.091,0,0,1,.672,2.52v4.718a4.313,4.313,0,0,1-.658,2.569,3.247,3.247,0,0,1-4.5-.007m3.689-.553a3.324,3.324,0,0,0,.392-1.785V94.709a3.252,3.252,0,0,0-.385-1.764,1.591,1.591,0,0,0-1.436-.588,1.635,1.635,0,0,0-1.456.581,3.208,3.208,0,0,0-.391,1.771v5.138a3.316,3.316,0,0,0,.391,1.792,1.607,1.607,0,0,0,1.456.6,1.569,1.569,0,0,0,1.429-.609"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_38" data-name="Path 38"
                          d="M239.715,91.615h2.716a2.488,2.488,0,0,1,2.065.784,3.5,3.5,0,0,1,.623,2.226,3.357,3.357,0,0,1-.651,2.142,2.416,2.416,0,0,1-2.023.826h-1.666v5.362h-1.064Zm2.422,5.18a1.946,1.946,0,0,0,1.491-.483,2.506,2.506,0,0,0,.441-1.687,4.2,4.2,0,0,0-.168-1.351,1.122,1.122,0,0,0-.574-.665,2.8,2.8,0,0,0-1.176-.2h-1.372v4.382Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_39" data-name="Path 39"
                          d="M247.722,91.615h1.064l2.241,9.842,2.281-9.842h1.064l.281,11.34h-.883l-.224-9.03-2.17,9.03h-.686l-2.142-9.016-.223,9.016h-.882Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_40" data-name="Path 40"
                          d="M257.593,91.615h4.1v.826H258.67V96.7h2.465v.784H258.67v4.676h3.053v.8h-4.13Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_41" data-name="Path 41"
                          d="M264.242,91.615h.77l3.612,8.806V91.615h.924v11.34h-.756l-3.626-8.9v8.9h-.924Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_42" data-name="Path 42"
                          d="M273.985,92.441h-2.142v-.826h5.292v.826h-2.086v10.514h-1.064Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_43" data-name="Path 43"
                          d="M291.632,102.185a4.3,4.3,0,0,1-.665-2.576V94.9a4.094,4.094,0,0,1,.671-2.527,2.672,2.672,0,0,1,2.254-.875,2.625,2.625,0,0,1,2.227.882,4.091,4.091,0,0,1,.672,2.52v4.718a4.313,4.313,0,0,1-.658,2.569,3.247,3.247,0,0,1-4.5-.007m3.689-.553a3.324,3.324,0,0,0,.392-1.785V94.709a3.252,3.252,0,0,0-.385-1.764,1.591,1.591,0,0,0-1.436-.588,1.635,1.635,0,0,0-1.456.581,3.208,3.208,0,0,0-.391,1.771v5.138a3.316,3.316,0,0,0,.391,1.792,1.607,1.607,0,0,0,1.456.6,1.569,1.569,0,0,0,1.429-.609"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_44" data-name="Path 44"
                          d="M299.619,91.615h.77L304,100.421V91.615h.924v11.34h-.756l-3.626-8.9v8.9h-.924Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_45" data-name="Path 45" d="M307.963,91.615h1.063v10.542h3.221v.8h-4.284Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <rect id="Rectangle_43" data-name="Rectangle 43" width="1.063" height="11.34"
                          transform="translate(3711.54 2010.615)" fill="#282828"/>
                    <path id="Path_46" data-name="Path 46"
                          d="M318.547,91.615h.77l3.612,8.806V91.615h.924v11.34H323.1l-3.626-8.9v8.9h-.924Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_47" data-name="Path 47"
                          d="M326.877,91.615h4.1v.826h-3.025V96.7h2.465v.784h-2.465v4.676h3.053v.8h-4.13Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_48" data-name="Path 48"
                          d="M337.349,91.615h1.064l2.241,9.842,2.281-9.842H344l.281,11.34H343.4l-.224-9.03-2.17,9.03h-.686l-2.142-9.016-.223,9.016h-.882Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_49" data-name="Path 49"
                          d="M349.011,91.615h1.119l2.408,11.34h-1.05l-.559-3.038h-2.7l-.574,3.038h-1.036Zm1.777,7.532-1.2-6.118-1.2,6.118Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_50" data-name="Path 50"
                          d="M354.974,91.615h2.562a2.761,2.761,0,0,1,2.17.735,3.173,3.173,0,0,1,.644,2.149,3.973,3.973,0,0,1-.35,1.778,1.651,1.651,0,0,1-1.218.882l1.736,5.8h-1.036l-1.666-5.572h-1.778v5.572h-1.064ZM357.48,96.6a1.742,1.742,0,0,0,1.428-.5,2.541,2.541,0,0,0,.406-1.6,2.6,2.6,0,0,0-.385-1.6,1.724,1.724,0,0,0-1.421-.49h-1.47V96.6Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_51" data-name="Path 51"
                          d="M363.192,91.615h1.077v5.992l3.025-5.992h1.021l-2.323,4.872,2.828,6.468h-1.051l-2.491-5.67-1.009,1.848v3.822h-1.077Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_52" data-name="Path 52"
                          d="M370.878,91.615h4.1v.826h-3.025V96.7h2.465v.784h-2.465v4.676h3.053v.8h-4.13Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_53" data-name="Path 53"
                          d="M378.928,92.441h-2.142v-.826h5.292v.826h-2.086v10.514h-1.064Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <rect id="Rectangle_44" data-name="Rectangle 44" width="1.063" height="11.34"
                          transform="translate(3781.553 2010.615)" fill="#282828"/>
                    <path id="Path_54" data-name="Path 54"
                          d="M388.56,91.615h.77l3.612,8.806V91.615h.924v11.34h-.756l-3.626-8.9v8.9h-.924Z"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <path id="Path_55" data-name="Path 55"
                          d="M397.3,102.178a4.729,4.729,0,0,1-.637-2.681V95.073a4.66,4.66,0,0,1,.63-2.667,2.543,2.543,0,0,1,2.24-.9,2.509,2.509,0,0,1,2.108.819,3.94,3.94,0,0,1,.637,2.443v.392h-1.008v-.392a3.526,3.526,0,0,0-.365-1.841,1.491,1.491,0,0,0-1.358-.567,1.776,1.776,0,0,0-1.141.322,1.559,1.559,0,0,0-.532.875,6.153,6.153,0,0,0-.133,1.407v4.662a3.945,3.945,0,0,0,.385,1.974,1.534,1.534,0,0,0,1.436.644,1.482,1.482,0,0,0,1.385-.651,3.971,3.971,0,0,0,.392-1.995v-1.33h-1.68v-.8h2.661v5.488h-.687l-.126-1.288a2.215,2.215,0,0,1-.728,1.064,2.1,2.1,0,0,1-1.316.378,2.43,2.43,0,0,1-2.163-.931"
                          transform="translate(3397.137 1919)" fill="#282828"/>
                    <rect id="Rectangle_45" data-name="Rectangle 45" width="0.889" height="11.218"
                          transform="matrix(1, -0.007, 0.007, 1, 3584.367, 2010.639)" fill="#fecd00"/>
                    <rect id="Rectangle_46" data-name="Rectangle 46" width="0.889" height="11.218"
                          transform="matrix(1, -0.007, 0.007, 1, 3680.672, 2010.573)" fill="#fecd00"/>
                    <rect id="Rectangle_47" data-name="Rectangle 47" width="402.863" height="130.999"
                          transform="translate(3397.137 1919)" fill="none"/>
                </g>
            </svg>

        </div>
    );
};

export default Coins;