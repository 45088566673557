export const questions = [
    {
        id: 1,
        question: 'A Jacsomedia Digitális Ügynökség által fejlesztett kiállítási aktivitásokhoz bérbe adjuk az eszközöket is, így neked csak a látogatókkal kell foglalkoznod, a többit mi intézzük.',
        answer_ids: [1, 2],
        correct_id: 1,
    },
    {
        id: 2,
        question: 'Michael Schumacher, német autóversenyző hétszeres Forma 1 világbajnok.',
        answer_ids: [1, 2],
        correct_id: 1,
    },
    {
        id: 3,
        question: 'A Forma 1-es autók 0-ról 100-ra 2.6 másodperc alatt gyorsúlnak fel.',
        answer_ids: [1, 2],
        correct_id: 1,
    },
    {
        id: 4,
        question: 'A Forma 1 törtételmében mindössze két magyar versenyző vett részt.',
        answer_ids: [1, 2],
        correct_id: 2,
    },
    {
        id: 5,
        question: 'Lionel Messi volt az első Argentin aki futamot tudott nyerni a Forma 1-ben.',
        answer_ids: [1, 2],
        correct_id: 2,
    },
    {
        id: 6,
        question: 'A 2021-es Hungaroring futamot a hétszer világbajnok Lewis Hamilton nyerte.',
        answer_ids: [1, 2],
        correct_id: 2,
    }
]

export const answers = [
    {
        id: 1,
        answer: 'igaz',
    },
    {
        id: 2,
        answer: 'hamis',
    },
]