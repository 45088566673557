import React, {useState} from 'react';
import ScratchModal from "./ScratchModal";

const ScratchCard = (props) => {
    const [card] = useState(props.card);
    const [isScratched, setIsScratched] = useState(card.isScratched);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const getClasses = () => {
        if (isScratched) {
            return "px-12 relative pointer-events-none rounded-2xl"
        }
        return "px-12 relative rounded-2xl"
    }
    return (
        <div className='bg-brand rounded-3xl kinect_clickable'>
            <div className={getClasses() + " kinect_clickable"} id={card.id} onClick={() => {
                setIsModalOpen(true)
            }}>
                {!isScratched ? <img src="assets/images/games/scratch/scratch-big.png" id="scratchForeground" alt=""
                                     className="absolute left-0 top-0 bottom-0 right-0 kinect_clickable"/> :
                    <img src="assets/images/games/scratch/scratchd.png" alt=""
                         className="absolute left-0 top-0 bottom-0 right-0"/>}
                <img src={card.image} className="z-20 max-w-[120px] mx-auto" alt=""/>
            </div>
            {isModalOpen && <ScratchModal card={card} isStratched={isScratched} setIsScratched={setIsScratched}
                                          setIsModalOpen={setIsModalOpen} setCardCount={props.setCardCount}
                                          cardCount={props.cardCount} setWinnerCardCount={props.setWinnerCardCount}
                                          winnerCardCount={props.winnerCardCount}/>}
        </div>

    );
};

export default ScratchCard;