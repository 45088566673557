export const questions = [
    {
        id: 1,
        question: 'A Jacsomedia Digitális Ügynökség komplex szolgáltatásokkal segíti az ügyfelek mindennapi munkáját. Jelszavunk „Minden egy kézben”. \n' +
            'Melyek a Jacsomedia főbb szolgáltatásai?',
        answerIds: [1, 2, 3, 4],  //lehet kevesebb is
        correctAnswers: [1, 2, 3]  //lehet több is  + itt adjuk meg h melyik válasz correct, de meg lehetne adni a válasznál is, de akkor minden kérdésnél az a válasz ha megjelenik, akkor az helyes lesz
    },
    {
        id: 2,
        question: 'Mi a civil neve Sailor Moonnak?',
        answerIds: [5, 6, 7],  //lehet kevesebb is
        correctAnswers: [6]  //lehet több is  + itt adjuk meg h melyik válasz correct, de meg lehetne adni a válasznál is, de akkor minden kérdésnél az a válasz ha megjelenik, akkor az helyes lesz
    },
    {
        id: 3,
        question: 'Melyik sorozat főhőse Ash Ketchum',
        answerIds: [8, 9, 10, 11],  //lehet kevesebb is
        correctAnswers: [10]  //lehet több is  + itt adjuk meg h melyik válasz correct, de meg lehetne adni a válasznál is, de akkor minden kérdésnél az a válasz ha megjelenik, akkor az helyes lesz
    },
    {
        id: 4,
        question: 'Mitől különleges az óriás macska a Totoro - A varázserdő titka-ban?',
        answerIds: [12, 13, 14, 15],  //lehet kevesebb is
        correctAnswers: [12]  //lehet több is  + itt adjuk meg h melyik válasz correct, de meg lehetne adni a válasznál is, de akkor minden kérdésnél az a válasz ha megjelenik, akkor az helyes lesz
    },
    {
        id: 5,
        question: 'A Demon Slayerben / Kimetsu no Yaiba-ban, kicsodája a démonlány a főszereplőnek?',
        answerIds: [16, 17, 18, 19],  //lehet kevesebb is
        correctAnswers: [19]  //lehet több is  + itt adjuk meg h melyik válasz correct, de meg lehetne adni a válasznál is, de akkor minden kérdésnél az a válasz ha megjelenik, akkor az helyes lesz
    },
]

export const answers = [

    {
        id: 1,
        answer: 'Kreatív tervezés és DTP',
    },
    {
        id: 2,
        answer: 'Szoftverfejlesztés',
    },
    {
        id: 3,
        answer: 'Online marketing',
    },
    {
        id: 4,
        answer: 'Ingatlanfejlesztés',
    },

    {
        id: 5,
        answer: 'Mamoru Chiba',
    },
    {
        id: 6,
        answer: 'Usagi Tsukino',
    },
    {
        id: 7,
        answer: 'Minako Aino',
    },
    {
        id: 8,
        answer: 'Digimon',
    },
    {
        id: 9,
        answer: 'Pokimane',
    },
    {
        id: 10,
        answer: 'Pokemon',
    },
    {
        id: 11,
        answer: 'Nincs is ilyen főhős',
    },
    {
        id: 12,
        answer: 'Egyben egy busz is',
    },
    {
        id: 13,
        answer: 'Egyben egy repülő is',
    },
    {
        id: 14,
        answer: 'Egyben egy hajó is',
    },
    {
        id: 15,
        answer: 'Csak nagy, nem különleges igazán',
    },
    {
        id: 16,
        answer: 'Szerelme',
    },
    {
        id: 17,
        answer: 'Barátja',
    },
    {
        id: 18,
        answer: 'Nővére',
    },
    {
        id: 19,
        answer: 'Húga',
    },


]
