import Counter from "../../../../Components/Counter/Counter";
import {maxReverseQuizNumber} from "../../../../Config/appConfig";
import Card from "../../../../Components/Card/Card";
import {answers} from "../../../../Config/reverseConfig";
import React, {useEffect, useState} from "react";
import AnswerButton from "../../../../Components/AnswerButton/AnswerButton";
import Banner from "../../../../Components/Banner/Banner";
import Timer from "../../../Timer/Timer";

const ReverseGame = (props) => {
        const [quizQuestions] = useState(props.quizQuestions);
        let [quizNumber, setQuizNumber] = useState(1);
        const [activeQuizAnswers, setActiveQuizAnswers] = useState([]);
        const [activeQuiz, setActiveQuiz] = useState({});
        const [selectedAnswers, setSelectedAnswers] = useState([]);
        const [isCounting, setIsCounting] = useState(true);
        let [countedGoodAnswers, setCountedGoodAnswers] = useState(0);
        let [countedAnswers, setCountedAnswers] = useState(0);
        const [isCorrectModal, setIsCorrectModal] = useState(false);
        const [isWrongModal, setIsWrongModal] = useState(false);

        useEffect(() => {
                if (quizNumber - 1 === quizQuestions.length) {
                    props.setActiveScreen('retry');
                } else {
                    const array = [...answers].filter((a) => quizQuestions[quizNumber - 1].answerIds.includes(a.id)).sort(() => Math.random() - .5);
                    setActiveQuiz(quizQuestions[quizNumber - 1]);
                    setActiveQuizAnswers(array);
                    setSelectedAnswers([]);
                    setIsCounting(true);
                }
                setIsWrongModal(false);
                setIsCorrectModal(false);
            }, [quizNumber]
        )


        useEffect(() => {
            if (quizNumber === quizQuestions.length) {
                if (countedGoodAnswers === maxReverseQuizNumber) {
                    props.setActiveScreen('exit');

                } else {
                    props.setActiveScreen('retry');
                }
                setIsWrongModal(false);
                setIsCorrectModal(false);
            }
        }, [countedGoodAnswers])


        const getQuizQuestion = () => {
            return <Card>
                <div className={'w-[80vw] flex flex-col p-4'}>
                    <Banner color={'blue'}>Fordított kvíz</Banner>
                    <div
                        className={'w-3/4 mx-auto text-3xl font-bold mt-5'}>  {activeQuiz.question}</div>

                </div>
            </Card>
        }
        const getAnswers = () => {
            return activeQuizAnswers.map(a => <AnswerButton key={a.id} text={a.answer}
                                                            click={() => {
                                                                if (countedAnswers < quizNumber) {
                                                                    setCountedAnswers(countedAnswers + 1);
                                                                    if (a.id === activeQuiz.correctAnswers) {
                                                                        setTimeout(() => {
                                                                            setIsCorrectModal(true);
                                                                        }, 500);
                                                                        countedGoodAnswers++;
                                                                    } else {
                                                                        setTimeout(() => {
                                                                            setIsWrongModal(true);
                                                                        }, 500);
                                                                    }
                                                                    setTimeout(() => {
                                                                        setCountedGoodAnswers(countedGoodAnswers);
                                                                        setTimeout(() => {
                                                                            setQuizNumber(quizNumber + 1)
                                                                        }, 10);
                                                                    }, 5000);
                                                                }
                                                            }}/>
            )
        }

        const gameEnd = () => {
            if (countedAnswers < 5) {
                props.setActiveScreen('timeout');
            } else {
                props.setActiveScreen('retry');
            }

        }


        return (
            <>
                <div>
                    <Counter>
                        {quizNumber + '/' + maxReverseQuizNumber}
                    </Counter>
                    <div className="flex flex-col mt-10">
                        {getQuizQuestion()}
                    </div>
                    <div className={'grid grid-cols-2 gap-x-32 gap-y-12 my-12 px-20'}>
                        {getAnswers()}
                    </div>
                    <div className={'fixed w-[100%] bottom-[50px] left-0'}>
                        <Timer time={60} onEnd={gameEnd}/>
                    </div>

                </div>
                {isCorrectModal &&
                    <div
                        className={'fixed flex items-center justify-center bottom-0 left-0 right-0 bg-[#00000080] top-0 z-50'}>
                        <div className={'w-8/12 mx-auto mb-[5%]'}>
                            <Card>
                                <div className={'mt-6 flex flex-col h-[75vh]'}>
                                    <Banner color={'green'}>kvízjáték</Banner>
                                    <div className={'text-6xl font-bold mt-10 mb-3'}>Gratulálunk, ez a helyes válasz!
                                    </div>
                                    <div className={'w-[80%] mx-auto mt-auto'}><img
                                        src="assets/images/games/reverse/figura_nyertel.png"
                                        alt="modal correct"/></div>
                                </div>
                            </Card>
                        </div>
                    </div>}
                {isWrongModal &&
                    <div
                        className={'fixed flex items-center justify-center bottom-0  left-0 right-0  bg-[#00000080] top-0 z-50'}>
                        <div className={'w-8/12 mx-auto mb-[5%]'}>
                            <Card>
                                <div className={'mt-6 flex flex-col h-[75vh]'}>
                                    <Banner color={'red'}>kvízjáték</Banner>
                                    <div className={'text-6xl font-bold mt-10 mb-3'}>Sajnos ez a válasz helytelen!
                                    </div>
                                    <div className={'w-[85%] mx-auto mt-auto'}><img
                                        src="assets/images/games/reverse/figura_vesztettel.png"
                                        alt="modal correct"/></div>
                                </div>
                            </Card>
                        </div>
                    </div>}
            </>
        );
    }
;

export default ReverseGame;