import React from 'react';
import Card from "../../Components/Card/Card";
import Banner from "../../Components/Banner/Banner";
import Button from "../../Components/Button/Button";
import classes from "./Exit.module.scss";

const Exit = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10 max-w-[600px]">
                        <Banner color={'green'}>Játék vége</Banner>
                        <div className="mx-auto mt-8 text-left">
                            <p className={'text-3xl font-bold mb-12'}>Köszönjük, hogy velünk játszottál!</p>
                            <p className={'text-3xl font-bold mb-12'}>Gratulálunk, sikeresen teljesítetted ezt a mini
                                játékot. Ha van kedved hozzá, próbálkozz
                                meg egy másikkal is.</p>
                            <p className={'text-3xl font-bold mb-12'}>Most térj vissza a játékválasztó oldalra!</p>
                        </div>
                        <div className="mx-auto mt-32">
                            <Button text={'Vissza'} click={() => props.setActiveView('games')}/>
                        </div>

                    </div>
                </Card>
                <div className={classes['wrapper']}>
                    <img src="assets/images/games/figura_boldog.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-10 mt-24 ' + classes['zoom']}
                         alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Exit;