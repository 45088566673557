import React, {useState} from 'react';
import Clouds from "../../../Components/Clouds/Clouds";
import Button from "../../../Components/Button/Button";
import QuizDescription from "./Screens/QuizDescription";
import QuizGame from "./Screens/QuizGame";
import Card from "../../../Components/Card/Card";
import Banner from "../../../Components/Banner/Banner";
import QuizGameover from "./Screens/QuizGameover";
import {questions} from "../../../Config/quizConfig";
import {maxQuizNumber} from "../../../Config/appConfig";
import Exit from "../../Exit/Exit";
import Retry from "../../Retry/Retry";


const Quiz = (props) => {
    const quizQuestions = [...questions].sort(() => Math.random() - .5).splice(0, maxQuizNumber);
    const [activeScreen, setActiveScreen] = useState('description');
    const [isCorrectModal, setIsCorrectModal] = useState(false);
    const [isWrongModal, setIsWrongModal] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState(0);

    const screens = {
        'description': <QuizDescription setActiveScreen={setActiveScreen}/>,
        'game': <QuizGame setActiveScreen={setActiveScreen} setIsCorrectModal={setIsCorrectModal}
                          setIsWrongModal={setIsWrongModal} correctAnswer={correctAnswer}
                          setCorrectAnswer={setCorrectAnswer} quizQuestions={quizQuestions}
                          isCorrectModal={isCorrectModal}/>,
        'gameover': <QuizGameover setActiveView={props.setActiveView} correctAnswer={correctAnswer}/>,
        'exit': <Exit setActiveView={props.setActiveView}/>,
        'retry': <Retry setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>,
    }
    return (
        <div>
            <Clouds>
                <div className="container mx-auto ">
                    <div className="absolute left-10 top-10">
                        <Button class={"back"} click={() => {
                            props.setActiveView('games')
                        }}/>
                    </div>
                    <div className={'relative z-10'}>{screens[activeScreen]}</div>

                </div>
                {isCorrectModal &&
                    <div
                        className={'absolute flex items-center justify-center bottom-0 bg-[#00000080] top-0 z-50 w-full h-full'}>
                        <div className={'w-8/12 mx-auto mb-[5%]'}>
                            <Card>
                                <div className={'mt-6 flex flex-col h-[75vh]'}>
                                    <Banner color={'green'}>kvízjáték</Banner>
                                    <div className={'text-6xl font-bold mt-10 mb-3 px-10'}>Gratulálunk, helyesen
                                        válaszoltál!
                                    </div>
                                    <div className={'w-9/12 mx-auto mt-auto'}><img
                                        src="assets/images/games/quiz/figura_nyertel.png"
                                        alt="modal correct"/></div>
                                </div>
                            </Card>
                        </div>
                    </div>}
                {isWrongModal &&
                    <div
                        className={'absolute flex items-center justify-center bottom-0 bg-[#00000080] top-0 z-50 w-full h-full'}>
                        <div className={'w-8/12 mx-auto mb-[5%]'}>
                            <Card>
                                <div className={'mt-6 flex flex-col h-[75vh]'}>
                                    <Banner color={'red'}>kvízjáték</Banner>
                                    <div className={'text-6xl font-bold mt-10 mb-3'}>Sajnos nem jól válaszoltál!
                                    </div>
                                    <div className={'w-[85%] mx-auto mt-auto'}><img
                                        src="assets/images/games/quiz/figura_vesztettel.png"
                                        alt="modal correct"/></div>
                                </div>
                            </Card>
                        </div>
                    </div>}
            </Clouds>
        </div>
    );
};

export default Quiz;