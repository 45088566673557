import React, {useEffect, useState} from 'react';
import Button from "../../../../Components/Button/Button";
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import {answers} from "../Config/truthConfig";
import Timer from "../../../Timer/Timer";
import CorrectModal from "../Components/CorrectModal";
import WrongModal from "../Components/WrongModal";
import Counter from "../../../../Components/Counter/Counter";

const TruthGame = (props) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [activeQuestion, setActiveQuestion] = useState(props.truthQuestions[0]);
    const [selectedAnswer, setSelectedAnswer] = useState(0);
    const [isCorrectModalVisible, setIsCorrectModalVisible] = useState(false);
    const [isWrongModalVisible, setIsWrongModalVisible] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0)

    useEffect(() => {
        if (selectedAnswer > 0) {
            setTimeout(() => {
                if (currentQuestion === props.truthQuestions.length) {
                    if (correctAnswers === props.truthQuestions.length) {
                        props.setActiveScreen('exit')
                    } else {
                        props.setActiveScreen('retry')
                    }
                } else {
                    setActiveQuestion(props.truthQuestions[currentQuestion]);
                }
                setSelectedAnswer(0);
                setIsCorrectModalVisible(false);
                setIsWrongModalVisible(false);
            }, 2500)
        }
    }, [currentQuestion])

    useEffect(() => {
        if (selectedAnswer !== 0) {
            checkSelectedAnswer()
        }
    }, [selectedAnswer])

    const checkSelectedAnswer = () => {

        if (activeQuestion.correct_id === selectedAnswer) {
            setIsCorrectModalVisible(true);
            setCorrectAnswers(correctAnswers + 1)
        } else {
            setIsWrongModalVisible(true);
        }

        const nextQuestion = currentQuestion + 1;
        setCurrentQuestion(nextQuestion);
    }

    const getAnswers = () => {
        return answers.map((answer) => (

            <Button click={() => {
                setSelectedAnswer(answer.id)
            }}
                    key={answer.id} text={answer.answer}/>
        ))
    }

    return (
        <div>
            <div className='flex justify-around min-h-screen'>
                <div className="flex my-10 mb-36">
                    <Counter>
                        {props.truthQuestions.length + '/' + currentQuestion}
                    </Counter>
                    <Card>
                        <div className="flex flex-col m-14">
                            <Banner color={'blue'}>igaz vagy hamis</Banner>
                            <div className="mt-7 font-bold max-w-[900px] text-left text-3xl">
                                <p className="my-5">{activeQuestion.question}</p>
                            </div>
                            <div className="flex justify-around items-center grow">
                                {getAnswers()}
                            </div>
                            <div className="flex mb-10 mt-auto">
                                <Timer time={60} onEnd={() => {
                                    props.setActiveScreen('timeout')
                                }}/>
                            </div>
                        </div>

                    </Card>
                </div>
            </div>
            {isCorrectModalVisible && <CorrectModal/>}
            {isWrongModalVisible && <WrongModal/>}
        </div>
    );
};

export default TruthGame;