import React, {useState} from 'react';

const Puzzle = (props) => {

    const dragStart = event => {
        let target = event.target
        event.dataTransfer.setData("puzzle_id", target.id)

        setTimeout(() => {
            target.style.display = "none";
        }, 0);
    }

    return (
        <img src={props.src} id={props.id} draggable={props.draggable} onDragStart={props.dragStart} holder={props.holder} onDrop={props.drop} onDragOver={props.dragOver} side={props.side} width={props.width} className={'z-50'} />
    )
}

export default Puzzle