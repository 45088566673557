import React, {useEffect, useState} from 'react';
import classes from './Timer.module.scss'

const Timer = (props) => {
    const [timer, setTimer] = useState(props.time);

    useEffect(() => {
        if (timer === 0) {
            props.onEnd();
        }
    }, [timer])

    useEffect(() => {
        setInterval(() => {
            setTimer(old => {
                if (old > 0) return --old;
                return old;
            })
        }, 1000)
    }, [])

    useEffect(() => {
        setTimer(props.time);
    }, [props.start])

    return (
        <div className='relative w-full'>
            <div className="w-full relative">
                <div
                    className="container mx-auto relative left-0 right-0  h-12 rounded-full flex max-w-[800px]">
                    <div
                        className={classes["timer"] + ' ' + ((timer < 10) ? (timer === 0) ? classes['over'] : classes['hurry'] : '')}>
                        <div className={classes['inner']} style={{
                            width: `calc(98% * (${timer / props.time}))`,
                            background: `${(timer > 20) ? "linear-gradient(#81EBFCFF, #05CBFCFF)" : timer > 10 ? "linear-gradient(#FFF036, #FEE133)" : "linear-gradient(#FA7070, #FA4C48)"}`,
                            boxShadow: `0 5px 0 ${timer > 20 ? "#21AFFC" : timer > 10 ? "#FECD00" : "#D4211C"}`
                        }}/>
                    </div>
                    <strong className={classes['counter']}>

                        {Math.floor(timer / 60) + ':' + ('0' + timer % 60).slice(-2)}
                    </strong>
                </div>
            </div>
        </div>

    )
}

export default Timer;