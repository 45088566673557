import React, {useState} from 'react';
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import Button from "../../../../Components/Button/Button";
import ScratchField from "./ScratchField";

const ScratchModal = (props) => {

    const [scratchedCanvas, setScratchedCanvas] = useState(false);

    return (
        <div className="absolute top-0 left-0 bottom-0 right-0 bg-[#00000080] z-50">
            <div className="mt-20">
                <Card>
                    <div className="p-10">
                        <Banner color='blue'>Kapard le!</Banner>
                        <div className="relative w-2/3 my-20 flex items-center justify-center mx-auto">
                            {!props.isScratched && <ScratchField setScratchedCanvas={setScratchedCanvas}/>}
                            <img src={props.card.image}
                                 className="px-20 z-10 mx-auto max-w-[400px] bg-brand rounded-2xl" alt=""/>
                        </div>
                        <Button class={'wrong'} text={'Bezár'} click={() => {
                            props.setIsModalOpen(false);

                            if (scratchedCanvas) {
                                props.setIsScratched(true);
                                props.setCardCount(props.cardCount + 1);

                                if (props.card.isWinner) {
                                    props.setWinnerCardCount(props.winnerCardCount + 1);
                                }
                            }
                        }}/>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ScratchModal;