import React, {useState} from 'react';
import Clouds from "../../../Components/Clouds/Clouds";
import Button from "../../../Components/Button/Button";
import Card from "../../../Components/Card/Card";
import Banner from "../../../Components/Banner/Banner";
import {questions} from "../../../Config/reverseConfig";
import {maxReverseQuizNumber} from "../../../Config/appConfig";
import Exit from "../../Exit/Exit";
import Retry from "../../Retry/Retry";
import ReverseGame from "./Screens/ReverseGame";
import ReverseDescription from "./Screens/ReverseDescription";
import ReverseTimeOut from "./Screens/ReverseTimeOut";


const Reverse = (props) => {
    const quizQuestions = [...questions].sort(() => Math.random() - .5).splice(0, maxReverseQuizNumber);
    const [activeScreen, setActiveScreen] = useState('description');
    const [correctAnswer, setCorrectAnswer] = useState(0);

    const screens = {
        'description': <ReverseDescription setActiveScreen={setActiveScreen}/>,
        'game': <ReverseGame setActiveScreen={setActiveScreen}
                             correctAnswer={correctAnswer}
                             setCorrectAnswer={setCorrectAnswer} quizQuestions={quizQuestions}
        />,
        'timeout': <ReverseTimeOut setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>,
        'exit': <Exit setActiveView={props.setActiveView}/>,
        'retry': <Retry setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>,
    }
    return (
        <
            div>
            < Clouds>
                < div
                    className="container mx-auto ">
                    < div
                        className="absolute left-10 top-10">
                        < Button

                            class

                                ={"back"}
                            click={() => {
                                props.setActiveView('games')
                            }
                            }
                        />
                    </div>
                    <div className={'relative z-10'}>{screens[activeScreen]}</div>

                </div>

            </Clouds>
        </div>
    );
};

export default Reverse;