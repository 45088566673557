import React from 'react';
import classes from "../Start/Start.module.scss";
import Card from "../../Components/Card/Card";
import Button from "../../Components/Button/Button";

const Home = (props) => {
    return (
        <div className={classes['marks'] + ' min-h-screen flex justify-center items-center'}>

            <Card>
                <div className="relative flex py-56 px-52 flex-col w-fit hidden 2xl:block">
                    <img src="assets/images/game-title.png"
                         className={'absolute max-w-[1000px] top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 '}
                         alt=""/>
                    <div className={"pt-7" + classes['opening-image']}>
                        <img src="assets/images/figura.png"
                             className={'absolute max-w-[800px] z-20 top-0 left-1/2 -translate-x-[55%] ' + classes['explosion']}
                             alt=""/>
                        <img src="assets/images/explosion.png"
                             className={'absolute z-10 max-w-[600px] top-0 left-1/2 -translate-x-[55%] ' + classes['explosion']}
                             alt=""/>
                    </div>
                    <div className="pb-16 pt-5 uppercase font-bold"/>
                    <div className="absolute -bottom-6 left-1/2 -translate-x-1/2 z-30">
                        <Button text={'start'} click={() => {
                            props.setActiveView('games')
                        }}/>
                    </div>
                </div>
                <div className="2xl:hidden text-2xl font-black p-10">
                    A játék full HD képernyőre készült, a megfelelő játékélmény érdekében használj teljes képernyős
                    nézetet!
                </div>
            </Card>
        </div>
    );
};

export default Home;
