import React from 'react';

const WIDTH = 400;
const HEIGHT = 281;

class ScratchField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isDrawing: false,
            startX: 0,
            startY: 0,
            kinectStartX: 0,
            kinectStartY: 0
        };
        this.canvasRef = React.createRef();
    }

    componentDidMount = () => {
        const image = document.getElementById('scratchForeground');
        const canvas = this.canvasRef.current;
        const context = canvas.getContext("2d");

        canvas.addEventListener("mousedown", this.scratchStart);
        canvas.addEventListener("mousemove", this.scratch);
        canvas.addEventListener("mouseup", this.scratchEnd);

        canvas.addEventListener("touchstart", this.scratchStart);
        canvas.addEventListener("touchmove", this.scratch);
        canvas.addEventListener("touchend", this.scratchEnd);

        document.addEventListener("kinect2-hand-statechange", this.kinectHandStateChange, {capture: true});
        document.addEventListener("kinect2-cursor-move", this.scratchKinect, {capture: true});

        context.drawImage(image, 0, 0, WIDTH, HEIGHT);
        context.lineWidth = 60;
        context.lineJoin = "round";
    };


    scratchStart = (e) => {
        const {layerX, layerY} = e;

        this.props.setScratchedCanvas(true);
        this.setState({
            isDrawing: true,
            startX: layerX,
            startY: layerY
        });
    };

    kinectHandStateChange = (e) => {
        if (e.state === 4 || e.state === 3) {
            let canvas = document.getElementById("canvas");
            let kinectcursor = document.getElementById("kinectcursor");
            let cursorTop = kinectcursor.getBoundingClientRect().top - canvas.getBoundingClientRect().top;
            let cursorLeft = kinectcursor.getBoundingClientRect().left - canvas.getBoundingClientRect().left;

            this.props.setScratchedCanvas(true);
            this.setState({
                isDrawing: true,
                kinectStartX: cursorLeft,
                kinectStartY: cursorTop,
            });
        }

        if (e.previousState === 4 || e.previousState === 3) {
            this.setState({
                isDrawing: false
            });
        }

    };

    scratch = (e) => {
        const {layerX, layerY} = e;
        const context = this.canvasRef.current.getContext("2d");

        if (!this.state.isDrawing) {
            return;
        }

        context.globalCompositeOperation = "destination-out";
        context.beginPath();
        context.moveTo(this.state.startX, this.state.startY);
        context.lineTo(layerX, layerY);
        context.closePath();
        context.stroke();

        this.setState({
            startX: layerX,
            startY: layerY
        });
    };

    scratchKinect = (e) => {
        const canvas = document.getElementById("canvas");
        const kinectPosX = e.x - canvas.getBoundingClientRect().left;
        const kinectPosY = e.y - canvas.getBoundingClientRect().top;
        const context = this.canvasRef.current.getContext("2d");

        if (!this.state.isDrawing) {
            return;
        }

        context.globalCompositeOperation = "destination-out";
        context.beginPath();
        context.moveTo(this.state.kinectStartX, this.state.kinectStartY);
        context.lineTo(kinectPosX, kinectPosY);
        context.closePath();
        context.stroke();

        this.setState({
            kinectStartX: kinectPosX,
            kinectStartY: kinectPosY,
        });
    };

    scratchEnd = (e) => {
        this.setState({
            isDrawing: false
        });
    };

    render() {
        return (
            <canvas
                className="absolute top-0 bottom-0 left-0 right-0 z-50"
                ref={this.canvasRef}
                id="canvas"
                width={`${WIDTH}px`}
                height={`${HEIGHT}px`}
            />
        );
    }
};

export default ScratchField;