import React from 'react';
import Button from "../../../../Components/Button/Button";
import Clouds from "../../../../Components/Clouds/Clouds";
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import classes from "./ScratchDescription.module.scss";

const ScratchDescription = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10">
                        <Banner color={'blue'}>Kaparós sorsjegy</Banner>
                        <div className="pt-4 font-bold max-w-[600px] text-left text-3xl">
                            <p className="mt-6 mb-5 text-3xl">Kaparós sorsjegy játék.</p>

                            <p className="mb-5 text-3xl">Ebben a játékban 5 mezőt kaparhatsz le és 3 db JM logót kell
                                találnod a
                                feladat teljesítéséhez. Először válaszd ki a sorsjegyet majd kapard le!</p>

                            <p className="mb-14 text-3xl">A feladat teljesítésére 90 mp áll rendelkezésre.</p>

                            <p>Készen állsz a kihívásra? Akkor rajta!</p>
                        </div>
                        <div className="mx-auto mt-16">
                            <Button text={'Start'}
                                    click={() => {
                                        props.setActiveScreen('game');
                                    }}/>
                        </div>
                    </div>
                </Card>
                <div className={'z-0 ' + classes['wrapper']}>
                    <img src="assets/images/games/scratch/scratch-description.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-20 mt-24 ' + classes['animations']}
                         alt=""/>
                </div>
            </div>
        </div>
    );
};

export default ScratchDescription;