import React from 'react';
import Button from "../../../../Components/Button/Button";
import Clouds from "../../../../Components/Clouds/Clouds";
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import classes from "./BonusDescription.module.scss";

const TruthDescription = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10">
                        <Banner color={'blue'}>bónusz játék</Banner>
                        <div className="pt-4 font-bold max-w-[600px] text-left text-3xl">
                            <p className="my-5">És most egy kis lazításként: ebben a játékban el
                                kell kapnod a véletlenszerűen lehulló tárgyakat a kosaraddal!</p>
                            <p className="my-5">Vigyázz a bombákkal, ha négyet összeszedsz belőlük
                                sajnos vége a játéknak!</p>
                            <p className="my-5">Készen állsz? Akkor vágjunk is bele!</p>
                        </div>
                        <div className=" mx-auto mt-44">
                            <Button text={'Start'} click={() => props.setActiveScreen('game')}/>
                        </div>

                    </div>

                </Card>
                <div className={'z-0 ' + classes['wrapper']}>
                    <img src=" assets/images/games/truth/description.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-10 mt-24 ' + classes['animations']}
                         alt=""/>
                </div>
            </div>
        </div>
    );
};

export default TruthDescription;