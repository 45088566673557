import React from 'react';
import classes from './Cloud2.module.scss'

const Cloud2 = (props) => {
    return (
        <div className={classes['wrapper']}>
            {props.children}
        </div>
    );
};

export default Cloud2;