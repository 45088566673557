import React from 'react';
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import Button from "../../../../Components/Button/Button";
import classes from "./TruthTimeOut.module.scss";

const TruthTimeOut = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10 max-w-[600px]">
                        <Banner color={'red'}>Játék vége</Banner>
                        <div className="mx-auto mt-8 text-left">
                            <p className={'text-3xl font-bold mb-12'}>Lejárt a játékidő!</p>

                            <p className={'text-3xl font-bold mb-12'}>Ettől gyorsabbnak kell lenned ám!</p>
                            <p className={'text-3xl font-bold mb-12'}>Ha van kedved
                                hozzá, próbálkozz meg egy másikkal, vagy játszd újra ezt a
                                játékot.</p>

                            <p className={'text-3xl font-bold mb-12'}>Mit teszel?</p>
                        </div>
                        <div className="flex justify-between mt-20">
                            <Button text={'Újra'} click={() => props.setActiveScreen('game')}/>
                            <Button text={'Másik játék'} click={() => props.setActiveView('games')}/>
                        </div>

                    </div>

                </Card>
                <div className={'z-0 ' + classes['wrapper']}>
                    <img src="assets/images/games/figura_szomi.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-52 mt-24 ' + classes['slide']}
                         alt=""/>
                </div>
            </div>


        </div>
    );
};

export default TruthTimeOut;