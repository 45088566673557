import React, {useState, useEffect} from 'react';
import {puzzles, holders} from "../Config/grabConfig";
import Puzzle from "../Components/Puzzle";
import Holder from "../Components/Holder";
import classes from './GrabGame.module.scss';
import Banner from "../../../../Components/Banner/Banner";
import Timer from "../../../Timer/Timer";


const GrabGame = (props) => {
    const [nbrOfPlacedPuzzles, setNbrOfPlacedPuzzles] = useState(0);
    const [draggedElement, setDraggedElement] = useState(null);
    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    const [gamePieces] = useState([...puzzles]);

    const getPuzzles = (side = "all") => {
        return gamePieces.map(puzzle => {
            if (puzzle.side === side) {
                return <Puzzle src={puzzle.src} draggable="true" id={puzzle.id} key={puzzle.id} holder={puzzle.holder}
                               side={puzzle.side} width={puzzle.width} drop={drop} dragOver={props.dragOver}
                               dragStart={dragStart}/>
            }
            return
        })
    };

    const getHolders = () => {
        return holders.map(holder =>
            <Holder src={holder.src} id={holder.id} key={holder.id} style={holder.styles} drop={drop}
                    dragOver={props.dragOver}/>
        )
    };

    const cursorMoveEventListener = function (cursorMoveEvent) {
        setCursorX(cursorMoveEvent.x);
        setCursorY(cursorMoveEvent.y);
    }

    document.addEventListener("kinect2-cursor-move", cursorMoveEventListener, {capture: true, once: true});

    useEffect(() => {
        if (draggedElement && document.getElementById(draggedElement).getAttribute("draggable") === "true") {
            document.getElementById(draggedElement).setAttribute("style", `position: absolute; left: ${cursorX - 30}px; top: ${cursorY - 30}px;`)
            document.getElementById(draggedElement).setAttribute("width", "100px")
        }
    }, [cursorX]);

    const handStateChangeEventListener = function (handStateChangeEvent) {
        // 2-palm, 3-fist, 4-lasso
        if (handStateChangeEvent.state === 3) {
            //started dragging
            gamePieces.forEach(puzzle => {
                let currentPuzzle = document.getElementById(puzzle.id);
                if (currentPuzzle !== null) {
                    let puzzleTop = currentPuzzle.getBoundingClientRect().top;
                    let puzzleLeft = currentPuzzle.getBoundingClientRect().left;
                    let puzzleRight = puzzleLeft + currentPuzzle.offsetWidth;
                    let puzzleBottom = puzzleTop + currentPuzzle.offsetHeight;
                    if (
                        cursorX > puzzleLeft &&
                        cursorX < puzzleRight &&
                        cursorY > puzzleTop &&
                        cursorY < puzzleBottom
                    ) {
                        setDraggedElement(puzzle.id);
                    }
                }
            })
        }

        if (handStateChangeEvent.previousState === 3 && handStateChangeEvent.state === 2) {
            let draggedPuzzle = document.getElementById(draggedElement);
            if (draggedPuzzle !== null) {
                draggedPuzzle.setAttribute("style", "display: block;");
                let targetHolder = document.getElementById(draggedPuzzle.getAttribute("holder"));
                let holderTop = targetHolder.getBoundingClientRect().top;
                let holderLeft = targetHolder.getBoundingClientRect().left
                let holderRight = holderLeft + targetHolder.offsetWidth;
                let holderBottom = holderTop + targetHolder.offsetHeight;

                if (
                    cursorX > holderLeft &&
                    cursorX < holderRight &&
                    cursorY > holderTop &&
                    cursorY < holderBottom
                ) {
                    targetHolder.appendChild(draggedPuzzle);
                    draggedPuzzle.setAttribute("draggable", "false");
                    draggedPuzzle.setAttribute("width", "100%");
                    draggedPuzzle.setAttribute("height", "100%");
                    setNbrOfPlacedPuzzles(nbrOfPlacedPuzzles + 1);
                }
            }

            setDraggedElement(null);
        }
    }

    document.addEventListener("kinect2-hand-statechange", handStateChangeEventListener, {capture: true, once: true});

    const dragStart = event => {
        let target = event.target
        event.dataTransfer.setData("puzzle_id", target.id)

        setTimeout(() => {
            target.style.display = "none";
        }, 0);
    }

    if (nbrOfPlacedPuzzles >= getPuzzles().length) {
        setTimeout(() => {
            document.removeEventListener("kinect2-cursor-move", cursorMoveEventListener);
            document.removeEventListener("kinect2-hand-statechange", handStateChangeEventListener);
            props.setActiveScreen('exit')
        }, 3000);
    }

    const onTimerEnd = () => {
        if (nbrOfPlacedPuzzles < getPuzzles().length) {
            document.removeEventListener("kinect2-cursor-move", cursorMoveEventListener, true);
            document.removeEventListener("kinect2-hand-statechange", handStateChangeEventListener, true);
            props.setActiveScreen('retry');
        }
    }

    const drop = event => {
        event.preventDefault();
        const puzzleId = event.dataTransfer.getData('puzzle_id');
        const puzzle = document.getElementById(puzzleId);
        puzzle.style.display = "block";

        if (puzzle.getAttribute("holder") === event.target.id) {
            event.target.appendChild(puzzle);
            puzzle.setAttribute("draggable", "false");
            puzzle.setAttribute("width", "100%");
            puzzle.setAttribute("height", "100%");
            setNbrOfPlacedPuzzles(nbrOfPlacedPuzzles + 1);
        }
    }

    return (
        <div className={'z-20 w-3/4 bg-white rounded-3xl flex items-center justify-center flex-col my-10'} onDrop={drop}
             onDragOver={props.dragOver}>
            <Banner color={'blue'}>Fogd és vidd</Banner>

            {(nbrOfPlacedPuzzles < getPuzzles().length) &&

                <div onDrop={drop} className={'pb-16'}>
                    <div className={'my-3 flex items-center justify-center'}>
                        <div className={'flex flex-col w-1/5 justify-between items-center'} id="puzzleHolderLeft"
                             onDrop={drop}>
                            {getPuzzles('left')}
                        </div>
                        <div className={'mb-8 relative w-3/5'}>
                            <img src="/assets/images/games/Grab/board-base.png" alt="Fogd és vidd"/>
                            {getHolders()}
                        </div>
                        <div className={'flex flex-col w-1/5 justify-between items-center'} id="puzzleHolderRight"
                             onDrop={drop}>
                            {getPuzzles('right')}
                        </div>
                    </div>
                    <Timer time={40} onEnd={onTimerEnd}/>
                </div>
            }
            {(nbrOfPlacedPuzzles >= (getPuzzles().length)) &&
                <div className={classes['completed'] + ' w-full bg-contain bg-no-repeat bg-center py-16'}
                     style={{backgroundImage: "url('/assets/images/games/Grab/stars.png')"}}>
                    <img src="/assets/images/games/Grab/board-completed.png"
                         className={classes['completed__pic'] + ' ' + 'block w-3/5 mx-auto'}/>
                </div>
            }
        </div>
    );
};

export default GrabGame;