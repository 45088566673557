export const bonusItems = [
    {
        id: 'berry',
        img: 'assets/images/games/bonus/berry.png',
        pointValue: 10,
        speedModifier: 1,
        isDanger: false,
    },
    {
        id: 'avocado-left',
        img: 'assets/images/games/bonus/avocado-left.png',
        pointValue: 5,
        speedModifier: 1,
        isDanger: false
    }, {
        id: 'avocado-right',
        img: 'assets/images/games/bonus/avocado-right.png',
        pointValue: 5,
        speedModifier: 1,
        isDanger: false
    },
    {
        id: 'half-melon',
        img: 'assets/images/games/bonus/half-melon.png',
        pointValue: 5,
        speedModifier: 1,
        isDanger: false
    },
    {
        id: 'peach',
        img: 'assets/images/games/bonus/peach.png',
        pointValue: 5,
        speedModifier: 1,
        isDanger: false
    },
    {
        id: 'peach-left',
        img: 'assets/images/games/bonus/peach-left.png',
        pointValue: 5,
        speedModifier: 1,
        isDanger: false
    },
    {
        id: 'peach-right',
        img: 'assets/images/games/bonus/peach-right.png',
        pointValue: 5,
        speedModifier: 1,
        isDanger: false
    },
    {
        id: 'grape',
        img: 'assets/images/games/bonus/grape.png',
        pointValue: 5,
        speedModifier: 1,
        isDanger: false
    },
    {
        id: 'bomb',
        img: 'assets/images/games/bonus/bomb.png',
        pointValue: 0,
        speedModifier: .8,
        isDanger: true,
    },
    {
        id: 'potion',
        img: 'assets/images/games/bonus/poti.png',
        pointValue: -1,
        speedModifier: .9,
        isDanger: false,
    },
    {
        id: 'coffee',
        img: 'assets/images/games/bonus/coffee.png',
        pointValue: -1,
        speedModifier: 1.2,
        isDanger: false
    }
]

export const hearts = [
    {
        id: 1,
        isFilled: true
    },
    {
        id: 2,
        isFilled: true
    },
    {
        id: 3,
        isFilled: true
    }
]