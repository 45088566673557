import React from 'react';
import {maxQuizNumber} from "../../../../Config/appConfig";

const QuizGameover = (props) => {
    const over = () => {
        if (props.correctAnswers === maxQuizNumber) {
            return 'yaay!'

        }
        return 'loser'
    }
    return (
        <div>
            {over()}
        </div>
    );
};

export default QuizGameover;