import React from 'react';
import Button from "../../../../Components/Button/Button";
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import classes from "./TruthDescription.module.scss";

const TruthDescription = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10">
                        <Banner color={'blue'}>igaz vagy hamis</Banner>
                        <div className="pt-4 font-bold max-w-[600px] text-left text-3xl">
                            <p className="my-5">Eldöntendő kérdéseket fogunk feltenni.</p>
                            <p className="my-5">Ebben a játékban felelned kell 5 kérdésre IGAZ vagy HAMIS válasszal.</p>
                            <p className="my-5">A játékra összesen 60 másodperc fog a rendelkezésedre állni.</p>
                            <p className="my-5">A helyes válaszok kiválasztásához vidd az egérmutatót a megfelelő gomb
                                felé.</p>
                            <p className="mt-10">Készen állsz a kihívásra? Akkor rajta!</p>
                        </div>
                        <div className="mt-16">
                            <Button text={'start'} click={() => props.setActiveScreen('game')}/>
                        </div>

                    </div>

                </Card>
                <div className={'z-0 ' + classes['wrapper']}>
                    <img src="assets/images/games/truth/description.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-10 mt-24 ' + classes['animations']}
                         alt=""/>
                </div>
            </div>


        </div>
    );
};

export default TruthDescription;