export const puzzles = [
    {
        id: "puzzle1",
        src: "/assets/images/games/Grab/puzzle1.png",
        holder: "holder1",
        width: "60%",
        side: "left"
    },
    {
        id: "puzzle2",
        src: "/assets/images/games/Grab/puzzle2.png",
        holder: "holder2",
        width: "39%",
        side: "right"
    },
    {
        id: "puzzle3",
        src: "/assets/images/games/Grab/puzzle3.png",
        holder: "holder3",
        width: "24%",
        side: "left"
    },
    {
        id: "puzzle4",
        src: "/assets/images/games/Grab/puzzle4.png",
        holder: "holder4",
        width: "42%",
        side: "left"
    },
    {
        id: "puzzle5",
        src: "/assets/images/games/Grab/puzzle5.png",
        holder: "holder5",
        width: "51%",
        side: "right"
    },
    {
        id: "puzzle6",
        src: "/assets/images/games/Grab/puzzle6.png",
        holder: "holder6",
        width: "48%",
        side: "right"
    }
];

export const holders = [
    {
        id: "holder1",
        src: "/assets/images/games/Grab/holder1.png",
        styles: {
            width: "20%",
            height: "25%",
            top: "19%",
            left: "11%"
        }
    },
    {
        id: "holder2",
        src: "/assets/images/games/Grab/holder2.png",
        styles: {
            width: "13%",
            height: "26%",
            top: "55%",
            left: "10%"
        }
    },
    {
        id: "holder3",
        src: "/assets/images/games/Grab/holder3.png",
        styles: {
            width: "8%",
            height: "11%",
            top: "60%",
            left: "48%"
        }
    },
    {
        id: "holder4",
        src: "/assets/images/games/Grab/holder4.png",
        styles: {
            width: "14%",
            height: "26%",
            top: "48%",
            left: "79%"
        }
    },
    {
        id: "holder5",
        src: "/assets/images/games/Grab/holder5.png",
        styles: {
            width: "17%",
            height: "22%",
            top: "20%",
            left: "60%"
        }
    },
    {
        id: "holder6",
        src: "/assets/images/games/Grab/holder6.png",
        styles: {
            width: "16%",
            height: "22%",
            top: "68%",
            left: "32%"
        }
    },
]