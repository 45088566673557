import React from 'react';
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";
import Button from "../../../../Components/Button/Button";
import classes from "./QuizDescription.module.scss";

const QuizDescription = (props) => {
    return (
        <div className='flex justify-around min-h-screen'>
            <div className="flex my-10 gap-x-6 ml-20 mb-32">
                <Card>
                    <div className="flex flex-col m-10 max-w-[600px]">
                        <Banner color={'blue'}>kvízjáték</Banner>
                        <div className="mx-auto mt-8 text-left">
                            <p className={'text-3xl font-bold mb-4'}>Feleletválasztó kvízjáték.</p>
                            <p className={'text-3xl font-bold mb-4'}>Ebben a játékban felelned kell 5 kérdésre úgy, hogy
                                minden kérdéshez 4 lehetséges
                                választ adunk meg.</p>
                            <p className={'text-3xl font-bold mb-4'}>Minden kérdés megválaszolására 30 mp áll majd
                                rendelkezésedre.</p>
                            <p className={'text-3xl font-bold mb-12'}>Vigyázz, akár több válasz is helyes lehet!</p>

                            <p className={'text-3xl font-bold'}>Készen állsz a kihívásra? Akkor rajta!</p>
                        </div>
                        <div className="mx-auto mt-16">
                            <Button text={'Start'} click={() => props.setActiveScreen('game')}/>
                        </div>

                    </div>

                </Card>
                <div className={'z-0 ' + classes['wrapper']}>
                    <img src="assets/images/games/quiz/figura_mosolyog.png"
                         className={'z-20 max-w-[800px] max-h-[680px] my-auto ml-10 mt-24 ' + classes['animations']}
                         alt=""/>
                </div>
            </div>


        </div>
    );
};

export default QuizDescription;