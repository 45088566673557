import logo from './logo.svg';
import './App.css';
import {useState} from "react";
import Start from "./Containers/Start/Start";
import Home from "./Containers/Home/Home";
import Games from "./Containers/Games/Games";
import Truth from "./Containers/Games/Truth/Truth";
import Quiz from "./Containers/Games/Quiz/Quiz";
import Grab from "./Containers/Games/Grab/Grab";
import Scratch from "./Containers/Games/Scratch/Scratch";
import Reverse from "./Containers/Games/Reverse/Reverse";
import Coins from "./Components/Coins/Coins";
import Falling from './Containers/Games/Falling/Falling'

const App = () => {
    const [activeView, setActiveView] = useState('home');
    const [isBonusUnlocked, setIsBonusUnlocked] = useState(true)

    const drop = event => {
        event.preventDefault();
        let puzzle = document.getElementById(event.dataTransfer.getData('puzzle_id'));
        puzzle.style.display = "block";
    }

    const view = {
        'start': <Start setActiveView={setActiveView}/>,
        'home': <Home setActiveView={setActiveView}/>,
        'games': <Games setActiveView={setActiveView} isBonusUnlocked={isBonusUnlocked}
                        setIsBonusUnlocked={setIsBonusUnlocked}/>,
        'truth': <Truth setActiveView={setActiveView}/>,
        'quiz': <Quiz setActiveView={setActiveView}/>,
        'grab': <Grab setActiveView={setActiveView} drop={drop}/>,
        'scratch': <Scratch setActiveView={setActiveView}/>,
        'reverse': <Reverse setActiveView={setActiveView}/>,
        'falling': <Falling setActiveView={setActiveView}/>

    }

    return (<div className="App bg-brand min-h-screen lg:overflow-hidden">
            {view[activeView]}
        <Coins coins={'100'} drop={drop}/>
    </div>);
}

export default App;
