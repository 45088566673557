import React from 'react';
import Card from "../../../../Components/Card/Card";
import Banner from "../../../../Components/Banner/Banner";

const WrongModal = () => {
    return (
        <div
            className={'absolute flex items-center justify-center left-0 bottom-0 bg-[#00000080] top-0 z-50 w-full h-full'}>
            <div className={'w-8/12 mx-auto'}>
                <Card>
                    <div className={'mt-6 flex flex-col h-[75vh]'}>
                        <Banner color={'red'}>igaz vagy hamis</Banner>
                        <div className={'text-6xl font-bold mt-10 mb-3'}>Sajnos ez a válasz helytelen…
                        </div>
                        <div className={'w-[85%] mx-auto mt-auto'}><img
                            src="assets/images/games/truth/figura_vesztettel.png"
                            alt="modal correct"/></div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default WrongModal;