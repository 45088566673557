export const gameTimeSeconds = 90;

export const cards = [
    {
        id: 1,
        image: 'assets/images/games/scratch/card-2.png',
        isScratched: false,
        isWinner: false
    }, {
        id: 2,
        image: 'assets/images/games/scratch/card-3.png',
        isScratched: false,
        isWinner: false
    }, {
        id: 3,
        image: 'assets/images/games/scratch/card-4.png',
        isScratched: false,
        isWinner: false
    }, {
        id: 4,
        image: 'assets/images/games/scratch/card-5.png',
        isScratched: false,
        isWinner: false
    }, {
        id: 5,
        image: 'assets/images/games/scratch/card-6.png',
        isScratched: false,
        isWinner: false
    }, {
        id: 6,
        image: 'assets/images/games/scratch/card-7.png',
        isScratched: false,
        isWinner: false
    }, {
        id: 7,
        image: 'assets/images/games/scratch/card-1.png',
        isScratched: false,
        isWinner: true
    }, {
        id: 8,
        image: 'assets/images/games/scratch/card-1.png',
        isScratched: false,
        isWinner: true
    }, {
        id: 9,
        image: 'assets/images/games/scratch/card-1.png',
        isScratched: false,
        isWinner: true
    }, {
        id: 10,
        image: 'assets/images/games/scratch/card-1.png',
        isScratched: false,
        isWinner: true
    }, {
        id: 11,
        image: 'assets/images/games/scratch/card-1.png',
        isScratched: false,
        isWinner: true
    }, {
        id: 12,
        image: 'assets/images/games/scratch/card-1.png',
        isScratched: false,
        isWinner: true
    }
]