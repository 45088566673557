import React, {useState} from 'react';
import TruthDescription from "./Screens/TruthDescription";
import Button from "../../../Components/Button/Button";
import Clouds from "../../../Components/Clouds/Clouds";
import {questions} from "./Config/truthConfig";
import TruthGame from "./Screens/TruthGame";
import Retry from "../../Retry/Retry";
import Exit from "../../Exit/Exit";
import TruthTimeOut from "./Screens/TruthTimeOut";

const Truth = (props) => {
    const maxTruthQuestion = 5;
    const [activeScreen, setActiveScreen] = useState('description');
    const [truthQuestions] = useState([...questions].sort(() => Math.random() - 0.5).splice(0, maxTruthQuestion))


    const screens = {
        'description': <TruthDescription setActiveScreen={setActiveScreen}/>,
        'game': <TruthGame setActiveView={props.setActiveView} truthQuestions={truthQuestions}
                           setActiveScreen={setActiveScreen}/>,
        'retry': <Retry setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>,
        'exit': <Exit setActiveView={props.setActiveView}/>,
        'timeout': <TruthTimeOut setActiveView={props.setActiveView} setActiveScreen={setActiveScreen}/>
    }


    return (
        <div>
            <Clouds>
                <div className="container mx-auto ">
                    <div className="absolute left-10 top-10">
                        <Button class={"back"} click={() => {
                            props.setActiveView('games')
                        }}/>
                    </div>
                    {screens[activeScreen]}
                </div>
            </Clouds>
        </div>
    );
}


export default Truth;