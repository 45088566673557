import React from 'react';
import Card from "../../Components/Card/Card";
import Button from "../../Components/Button/Button";
import classes from "../Start/Start.module.scss";
import Clouds from "../../Components/Clouds/Clouds";

const Games = (props) => {
    return (
        <Clouds>
            <div className="container mx-auto pt-10 ">
                <div className="absolute left-10 top-10">
                    <Button class={"back"} click={() => {
                        props.setActiveView('home')
                    }}/>
                </div>

                <div className="grid grid-cols-3 w-full gap-y-32 justify-around">
                    <Card>
                        <div className="relative px-5 flex flex-col w-fit">
                            <div className="pt-14">
                                <img src="assets/images/games/hands.png" className='max-w-[260px] kinect_fistareaclick'
                                     alt="" onClick={() => {
                                    props.setActiveView('truth')
                                }}/>
                            </div>
                            <div className="pb-16 pt-10 uppercase font-bold text-3xl kinect_fistareaclick"
                                 onClick={() => {
                                     props.setActiveView('truth')
                                 }}>Igaz-Hamis
                            </div>
                            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                                <Button text={'Választ'} click={() => {
                                    props.setActiveView('truth')
                                }}/>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className="relative px-5 flex flex-col w-fit">
                            <div className="pt-10">
                                <img src="assets/images/games/quiz.png" className='max-w-[200px] kinect_fistareaclick'
                                     alt="" onClick={() => {
                                    props.setActiveView('quiz')
                                }}/>
                            </div>
                            <div className="pb-16 pt-10 uppercase font-bold text-3xl kinect_fistareaclick"
                                 onClick={() => {
                                     props.setActiveView('quiz')
                                 }}>kvíz
                            </div>
                            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                                <Button text={'Választ'} click={() => {
                                    props.setActiveView('quiz')
                                }}/>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className="relative px-5 flex flex-col w-fit">
                            <div className="pt-10">
                                <img src="assets/images/games/grab.png" className='max-w-[180px] kinect_fistareaclick'
                                     alt="" onClick={() => {
                                    props.setActiveView('grab')
                                }}/>
                            </div>
                            <div className="pb-16 pt-9 uppercase font-bold text-3xl kinect_fistareaclick"
                                 onClick={() => {
                                     props.setActiveView('grab')
                                 }}>fogd és vidd
                            </div>
                            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                                <Button text={'Választ'} click={() => {
                                    props.setActiveView('grab')
                                }}/>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className="relative px-5 flex flex-col w-fit">
                            <div className="pt-10 pl-14">
                                <img src=" assets/images/games/scratch.png"
                                     className='max-w-[180px] kinect_fistareaclick'
                                     alt="" onClick={() => {
                                    props.setActiveView('scratch')
                                }}/>
                            </div>
                            <div className="pb-16 pt-14 uppercase font-bold text-3xl kinect_fistareaclick"
                                 onClick={() => {
                                     props.setActiveView('scratch')
                                 }}>kaparós sorsjegy
                            </div>
                            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                                <Button text={'Választ'} click={() => {
                                    props.setActiveView('scratch')
                                }}/>
                            </div>
                        </div>
                    </Card>
                    <Card>
                        <div className="relative px-5 flex flex-col w-fit">
                            <div className="pt-10 pl-6">
                                <img src="assets/images/games/reverse.png"
                                     className='max-w-[180px] kinect_fistareaclick'
                                     alt="" onClick={() => {
                                    props.setActiveView('reverse')
                                }}/>
                            </div>
                            <div className="pb-16 pt-6 uppercase font-bold text-3xl kinect_fistareaclick"
                                 onClick={() => {
                                     props.setActiveView('reverse')
                                 }}>fordított kvíz
                            </div>
                            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                                <Button text={'Választ'} click={() => {
                                    props.setActiveView('reverse')
                                }}/>
                            </div>
                        </div>
                    </Card>
                    {
                        !props.isBonusUnlocked ? <Card class={'secondary'}>
                            <div className="relative px-5 flex flex-col w-fit" onClick={e => {
                                if (e.detail === 3) {
                                    props.setIsBonusUnlocked(true)
                                }
                            }

                            }>
                                <div className="py-10">
                                    <img src="assets/images/games/locket.png" alt=""
                                         className={'max-h-[150px] mx-auto'}/>
                                </div>

                                <div
                                    className={"text-3xl uppercase font-bold text-brand_light px-3 rounded-3xl bg-brand " + classes['closed']}>
                                    <div>lezárva
                                    </div>
                                </div>

                                <div
                                    className={"text-3xl uppercase font-bold text-white " + classes['bonus']}>
                                    <div>bónusz
                                    </div>
                                </div>

                            </div>
                        </Card> : <Card>
                            <div className="relative px-5 flex flex-col w-fit">
                                <div className="pt-10 pl-3">
                                    <img src="assets/images/games/box.png"
                                         className='max-w-[180px] kinect_fistareaclick'
                                         alt="" onClick={() => {
                                        props.setActiveView('falling')
                                    }}/>
                                </div>
                                <div className="pb-16 pt-12 uppercase font-bold text-3xl kinect_fistareaclick"
                                     onClick={() => {
                                         props.setActiveView('falling')
                                     }}>Bónusz játék
                                </div>
                                <div className="absolute -bottom-6 left-1/2 -translate-x-1/2">
                                    <Button text={'Választ'} click={() => {
                                        props.setActiveView('falling')
                                    }}/>
                                </div>
                            </div>
                        </Card>
                    }
                </div>
            </div>

        </Clouds>
    )
        ;
};

export default Games;